import { isObject } from 'lodash';
import {
  queryGame,
  queryGameByUser,
  queryGameWithoutCancel,
  queryGameByUserWithoutCancel,
  queryGameTag,
  queryGameTagByGameClass,
  launchUrl,
  launchDemoUrl,
  addFavorite,
  removeFavorite,
  queryGameInfo,
} from '~/api-tc/game';

import {
  closeGameFrame,
  gameFrameFullScreen,
} from '~/utils/gameframe';

import { cryptoUtil } from '~/utils/crypto';
import common from '~/utils/common';
import { LiveGameKindCondition } from '~/utils/gameKindMapping';
import {
  DesktopDeviceType,
  MobileDeviceType,
  StatusEnable,
  StatusDisable,
} from '~/utils/constant';

import gameConstants from '~/utils/constant/game';
import { getLoadingHtml } from '~/utils/loading';

const {
  launch: {
    type: launchType,
  },
  gameClass: {
    subType: gameClassSubType,
  },
  gameTag,
  DEFAULT_PROMOTION_GAME_MAX_COUNT,
} = gameConstants;

export const appUrlGenerator = (gameItem, { gameLaunchType, gameLaunch }) => {
  const { brand, gameKindId } = gameItem;
  let urlPrefix = `native://app/v2/game/launch?platform=${brand}&gType=${gameKindId}`;

  if (gameLaunchType == launchType.HTML) return `${urlPrefix}&htmlcode=${btoa(gameLaunch)}`;

  switch (brand) {
    case 'HBN': {
      let hbnGameLaunchUrl = encodeURIComponent(`${window.location.origin}/gameSetting/HBN/index.html?gameLaunchUrl=${gameLaunch}&ifrm=1`);
      return `${urlPrefix}&url=${hbnGameLaunchUrl}`;
    }
    case 'KISS918':
    case 'SV':
      return `native://webview/v2/openfullview?isblank=true&url=${encodeURIComponent(gameLaunch)}`;
    default: {
      let gameLaunchUrl = encodeURIComponent(gameLaunch);
      return `${urlPrefix}&url=${gameLaunchUrl}`;
    }
  }
};

export const urlGenerator = ({ gameLaunchType, gameLaunch }) => {
  switch (gameLaunchType) {
    case launchType.HTML:
      return `${document.location.origin}/loading.html?launchHTML=${cryptoUtil.encrypt(gameLaunch)}`;
    default:
      return gameLaunch;
  }
};

const launchPostDomain = ({ brand }) => {
  switch (brand) {
    case 'KISS918':
      return `${document.location.origin}/static918.html`;
    case 'SV':
      return `${document.location.origin}/staticStarVegas.html`;
    default:
      return document.location.origin;
  }
};

const initSearchFilter = ({ brand, gameKind, gameTag }) => ({
  gameSelectType: 'all',
  brand,
  gameKind,
  gameTag,
  gameName: '',
  sorts: gameConstants.gameTag.sorts.DEFAULT,
  limit: {
    startIndex: 0,
    pageSize: 50,
  },
});

const launchGameUrlGenerator = ({
  gameItem, rootState, commit, isDemo = false,
}) => {
  let launchGameFn = isDemo ? launchDemoUrl : launchUrl;
  let { deviceType } = rootState;
  let hasDeviceTypeProperty = Object.hasOwnProperty.call(gameItem, 'deviceType');
  // TC-402 遊戲類型為Live的遊戲利用state.deviceType來做開啟遊戲開哪種版本為依據 (4, 10 ~ 20)
  // 目前 gameKindId 為數字 int 符合 4, 10~20 都被定義為Live Game
  if (!LiveGameKindCondition(gameItem.gameKindId)) deviceType = MobileDeviceType;
  return launchGameFn({
    gameId: gameItem.gameId,
    gameBranch: gameItem.brand,
    domain: launchPostDomain(gameItem),
    deviceType: MobileDeviceType,
    gameLang: rootState.lang,
  })
    .then(({ data }) => {
      commit('SET_LAUNCH_GAME', data);
      return data;
    });
};

const launchGame = ({
  gameItem, rootState, commit, isDemo = false,
}) => launchGameUrlGenerator({
  gameItem, rootState, commit, isDemo,
});

const state = () => ({
  selectedGameClass: {
    gameClassId: 0,
    status: 1,
    name: '',
    gameKind: [],
    brand: [],
    webBanner: '',
    appBanner: '',
    default: true,
  },
  gameList: [],
  searchFilter: initSearchFilter({ brand: undefined, gameKind: undefined, gameTag: undefined }),
  slotGameClassSelected: false,
  fisherGameClassSelected: false,
  arcadeGameClassSelected: false,
  moreGameSwitch: true,
  queryLoading: false,
  // TC-2553 Custom game list
  customGameList: {},
  gameDataSource: {
    0: {
      imgUrl: '',
      minImgUrl: '',
      gameName: '',
      brand: '',
      gameKindId: 1,
      status: 1,
      isFavorite: 0,
      isNew: 0,
      playCount: 0,
    },
  },
  //
  [gameTag.HOT_GAME]: {
    displayNameList: [],
    records: [],
  },
  [gameTag.PROMOTE_GAMES_1]: {
    displayNameList: [],
    records: [],
  },
  [gameTag.PROMOTE_GAMES_2]: {
    displayNameList: [],
    records: [],
  },
  gameTagList: [],
  gameTagListByGameClass: [],
  selectedGameTag: {
    brand: [],
    id: null,
  },
  launchGame: {
    type: gameConstants.launch.type.HTML,
    url: '',
    html: '',
  },
});

const standardGameListSettingGenerator = (gameSelectType) => ({
  gameSelectType,
  sorts: gameConstants.gameTag.sorts[gameSelectType] ?? gameConstants.gameTag.sorts.DEFAULT,
  limit: {
    startIndex: 0,
    pageSize: DEFAULT_PROMOTION_GAME_MAX_COUNT,
  },
});

const actions = {
  actionQueryGame({ rootState, commit }, { gameSearchFilter, withoutCancel, noLoading }) {
    if (!rootState.member.token) return;
    if (!noLoading) {
      commit('setGameState', { queryLoading: true });
    }
    const {
      brand, gameKind, gameTag, ...other
    } = gameSearchFilter;
    let dataProcess = (value) => {
      switch (typeof (value)) {
        case 'string':
        case 'number':
          return [value];
        case 'object':
          return Array.isArray(value) ? value : undefined;
        default:
          return undefined;
      }
    };
    let postData = {
      deviceType: rootState.deviceType,
      brand: dataProcess(brand),
      gameKind: dataProcess(gameKind),
      gameTag: dataProcess(gameTag),
      ...other,
    };
    // let queryGameReq = (rootState.loginStatus) ? queryGameByUser : queryGame;
    let queryGameReq = queryGameByUser;
    if (withoutCancel) queryGameReq = queryGameByUserWithoutCancel;
    return queryGameReq(postData)
      .then(({ data }) => {
        commit('setGameState', { queryLoading: false });
        commit('setGameDataSource', data);
        return data;
      });
  },
  actionQueryHotGame({ dispatch, commit }) {
    return dispatch('actionQueryGame', { gameSearchFilter: standardGameListSettingGenerator(gameTag.HOT_GAME), withoutCancel: true })
      .then((data) => {
        commit('setStandardGameList', { data, listName: gameTag.HOT_GAME });
      });
  },
  actionQueryPromoteGames1({ dispatch, commit }) {
    return dispatch('actionQueryGame', { gameSearchFilter: standardGameListSettingGenerator(gameTag.PROMOTE_GAMES_1), withoutCancel: true })
      .then((data) => {
        commit('setStandardGameList', { data, listName: gameTag.PROMOTE_GAMES_1 });
      });
  },
  actionQueryPromoteGames2({ dispatch, commit }) {
    return dispatch('actionQueryGame', { gameSearchFilter: standardGameListSettingGenerator(gameTag.PROMOTE_GAMES_2), withoutCancel: true })
      .then((data) => {
        commit('setStandardGameList', { data, listName: gameTag.PROMOTE_GAMES_2 });
      });
  },
  actionLaunchGame({ rootState, commit }, gameItem) {
    return launchGame({ gameItem, rootState, commit });
  },
  actionLaunchDemoGame({ rootState, dispatch, commit }, gameItem) {
    let closeDialogAction = rootState.isSubModalOpen ? 'actionCloseSubDialog' : 'actionCloseDialog';
    dispatch(closeDialogAction, null, { root: true });
    return launchGame({
      gameItem, rootState, commit, isDemo: true,
    });
  },
  actionQueryLaunchGameUrl({ rootState, commit }, gameItem) {
    return launchGameUrlGenerator({ gameItem, rootState, commit });
  },
  actionFavoriteToggle({
    rootState, state, dispatch, commit,
  }, gameItem) {
    let ajaxMethod = gameItem.isFavorite ? removeFavorite : addFavorite;
    const deviceType = rootState.deviceType;
    const deferAction = () => ajaxMethod({
      gameId: gameItem.gameId,
      deviceType,
    })
      .then(() => {
        commit('setToggleFavoriteStatus', gameItem.gameId);
      });
    return deferAction();
  },
  actionGameClassSelected({ state, dispatch, commit }, { gameClassItem, withoutCancel = false }) {
    if (gameClassItem === undefined) return;
    if (gameClassItem.gameClassId == state.selectedGameClass.gameClassId) {
      return dispatch('actionFilterSelected', { brand: gameClassItem.brand.map((i) => i.brand), withoutCancel });
    }
    commit('setGameState', { gameList: [] });
    commit('setSelectedGameClass', gameClassItem);
    return dispatch('actionQueryGame', { gameSearchFilter: state.searchFilter, withoutCancel })
      .then(({ records }) => {
        commit('setGameState', { gameList: records.map((i) => i.gameId) });
        return records;
      });
  },
  actionQueryGameMore({ state, dispatch, commit }, number) {
    if (!state.moreGameSwitch) return;
    if (state.queryLoading) return;
    const { startIndex, pageSize } = state.searchFilter.limit;
    const filter = {
      ...state.searchFilter,
      limit: {
        startIndex: (number !== undefined) ? startIndex + number : startIndex + 50,
        pageSize: (number !== undefined) ? number : 50,
      },
    };

    dispatch('actionQueryGame', { gameSearchFilter: filter, noLoading: true })
      .then(({ records, count }) => {
        commit('setGameState', { gameList: state.gameList.concat(records.map((i) => i.gameId)), moreGameSwitch: count > state.gameList.length });
        commit('setGameSearchFilter', filter);
      });
  },
  actionFilterSelected({
    rootState, state, commit, dispatch,
  }, filter = {}) {
    const { withoutCancel } = filter;
    // if (filter.gameSelectType === 'recently' || filter.gameSelectType === 'favorite') {
    //   if (!rootState.loginStatus) {
    //     dispatch('actionOpenDialog', { componentName: 'login', deferAction: () => dispatch('actionFilterSelected', filter) }, { root: true });
    //     return;
    //   }
    // }
    commit('setGameState', { moreGameSwitch: true });
    if (filter.sorts == undefined) filter.sorts = gameConstants.gameTag.sorts[filter.gameSelectType] ?? gameConstants.gameTag.sorts.DEFAULT;
    commit('setGameSearchFilter', filter);
    return dispatch('actionQueryGame', { gameSearchFilter: state.searchFilter, withoutCancel })
      .then(({ records }) => commit('setGameState', { gameList: records.map((i) => i.gameId) }));
  },
  actionFilterSelectedAllBrand({
    commit, dispatch, state, rootState,
  }) {
    dispatch('actionFilterSelected', {
      brand: rootState.initData.platformList,
      gameSelectType: state.selectedGameTag.name,
      limit: {
        startIndex: 0,
        pageSize: 50,
      },
    });
  },
  actionFilterTop50({
    rootState, state, dispatch, commit,
  }) {
    const brandData = rootState.initData.gameClassList[0].brand.map((item) => item.brand);
    dispatch('actionFilterSelected',
      {
        gameSelectType: 'top50',
        brand: brandData,
        limit: {
          startIndex: 0,
          pageSize: 50,
        },
      });
    commit('setGameState', { moreGameSwitch: false });
  },
  actionFilterFav({
    rootState, dispatch, commit,
  }) {
    dispatch('actionFilterSelected',
      {
        gameSelectType: 'favorite',
        brand: undefined,
        gameKind: undefined,
        limit: {
          startIndex: 0,
          pageSize: 50,
        },
      });
  },
  actionFilterTagGames({ commit, dispatch, state }, { withoutCancel }) {
    const gameSearchFilter = {
      ...state.searchFilter,
      gameSelectType: state.selectedGameTag.name,
      brand: undefined,
    };
    commit('setGameState', { searchFilter: gameSearchFilter, gameList: [] });
    return dispatch('actionQueryGame', { gameSearchFilter, withoutCancel })
      .then(({ records }) => {
        commit('setGameState', { gameList: records.map((i) => i.gameId) });
        return records;
      });
  },
  actionQueryCustomGameList({ state, dispatch, commit }, {
    gameSearchFilter, listName, append = false, forceUpdate = false,
  }) {
    if (!forceUpdate && state.customGameList[listName]) return Promise.resolve();
    return dispatch('actionQueryGame', { gameSearchFilter, withoutCancel: true })
      .then((data) => {
        commit('setCustomGameList', { data, listName, append });
      });
  },
  actionQueryGameTag({ commit }) {
    return queryGameTag({ type: gameTag.type.CUSTOMIZED })
      .then(({ data: { data } }) => {
        commit('setGameTagList', data);
      });
  },
  actionQueryGameTagByGameClass({ commit }, payload) {
    return queryGameTagByGameClass(payload)
      .then(({ data: { data } }) => {
        commit('setGameTagListByGameClass', data);
      });
  },
  actionInitSelectedGameTag({ commit, rootState }, payload) {
    const selectedGameTag = isObject(payload)
      ? payload
      : rootState.initData.gameTagList.find(((tag) => tag.url === payload)) || { id: null, brand: [] };
    commit('setGameSearchFilter', {
      limit: {
        startIndex: 0,
        pageSize: 50,
      },
      sorts: gameConstants.gameTag.sorts[payload.name] ?? gameConstants.gameTag.sorts.DEFAULT,
    });
    commit('setGameState', { selectedGameTag, moreGameSwitch: true });
  },
  async actionQueryGameInfo({ rootState }, payload) {
    return queryGameInfo(payload)
      .then(({ data }) => {
        const authorization = common.getLocalStorageValue('Authorization');
        const refreshToken = common.getLocalStorageValue('RefreshToken');
        const token = new URLSearchParams();
        token.append('ocms-currency', rootState.currencyCode);
        token.append('authorization', authorization);
        token.append('refreshtoken', refreshToken);
        return {
          ...data,
          imgUrl: `${data.imgUrl}?${token.toString()}`,
        };
      })
      .catch(() => null);
  },
};

const getters = {
  getGameDisplayName: (state, getters, rootState) => (tagName) => {
    if (state[tagName].displayNameList.length == 0) return '';
    return state[tagName].displayNameList.find((i) => i.lang == rootState.lang).description;
  },
  getCustomGameList: (state) => (listName) => {
    if (!Array.isArray(state.customGameList[listName])) return [];
    return state.customGameList[listName];
  },
};

const mutations = {
  // TC-1302 GameKind = 1 & subType = 1 為Slot另開 APP 分類
  setSelectedGameClass(state, gameClassItem) {
    Object.assign(state, {
      selectedGameClass: gameClassItem,
      moreGameSwitch: true,
      slotGameClassSelected: gameClassItem.subType == gameClassSubType.SLOT,
      fisherGameClassSelected: gameClassItem.subType == gameClassSubType.FISHER,
      arcadeGameClassSelected: gameClassItem.subType == gameClassSubType.BAR,
      searchFilter: initSearchFilter({
        brand: gameClassItem.brand.map((i) => i.brand),
        gameKind: gameClassItem.gameKind,
      }),
      default: false,
    });
  },
  setGameState(state, newState) {
    Object.assign(state, newState);
  },
  setGameSearchFilter(state, newFilter) {
    Object.assign(state.searchFilter, {
      limit: {
        startIndex: 0,
        pageSize: 50,
      },
    }, newFilter);
  },
  setGameDataSource(state, { records }) {
    let gameDataSource = records.reduce((result, item) => {
      result[item.gameId] = item;
      return result;
    }, {});
    state.gameDataSource = { ...state.gameDataSource, ...gameDataSource };
    // 不要使用 Object.assign 會有 vuex state 新增 property 沒有 dep issue.
  },
  setStandardGameList(state, { data: { records, displayNameList = [] }, listName, append = false }) {
    let recordsIndexArr = records.map((i) => i.gameId);
    state[listName].displayNameList = displayNameList;
    if (!Array.isArray(state[listName].records)) state[listName].records = [];
    if (append) {
      state[listName].records = state[listName].records.concat(recordsIndexArr);
    } else {
      state[listName].records = recordsIndexArr;
    }
  },
  setCustomGameList(state, { data: { records }, listName, append = false }) {
    let recordsIndexArr = records.map((i) => i.gameId);
    if (!Array.isArray(state.customGameList[listName])) state.customGameList = { ...state.customGameList, ...{ [listName]: [] } };
    state.customGameList[listName] = state.customGameList[listName].concat(recordsIndexArr);
    if (append) {
      state.customGameList[listName] = state.customGameList[listName].concat(recordsIndexArr);
    } else {
      state.customGameList[listName] = recordsIndexArr;
    }
  },
  setToggleFavoriteStatus(state, gameId) {
    state.gameDataSource[gameId].isFavorite = (state.gameDataSource[gameId].isFavorite) ? StatusDisable : StatusEnable;
  },
  setGameTagList(state, gameTagList) {
    state.gameTagList = gameTagList;
  },
  setGameTagListByGameClass(state, gameTagListByGameClass) {
    state.gameTagListByGameClass = gameTagListByGameClass;
  },
  SET_LAUNCH_GAME: (state, data) => {
    state.launchGame.type = data.gameLaunchType;

    switch (data.gameLaunchType) {
      case gameConstants.launch.type.HTML:
        state.launchGame.html = data.gameLaunch;
        state.launchGame.url = null;
        break;
      case gameConstants.launch.type.URL:
      default:
        state.launchGame.url = data.gameLaunch.replace(/http:\/\//g, 'https://');
        state.launchGame.html = null;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
