const state = {
  count: 180,
  lock: false,
  tempData: null,
  firstClick: false,
  dateTime: null,
  phoneRegister: {
    count: 180,
    lock: false,
    tempData: null,
    firstClick: false,
    dateTime: null,
  },
  updateMemberInfo: {
    count: 180,
    lock: false,
    tempData: null,
    firstClick: false,
    dateTime: null,
  },
};

const mutations = {
  SET_TIMER_COUNT: (state, count) => {
    state.count = count;
  },
  SET_TIMER_LOCK: (state, lock) => {
    state.lock = lock;
  },
  SET_TIMER_TEMP_DATA: (state, data) => {
    state.tempData = data;
  },
  SET_FIRST_CLICK: (state, firstClick) => {
    state.firstClick = firstClick;
  },
  SET_TIMER_DATE_TIME: (state, dateTime) => {
    state.dateTime = dateTime;
  },
  SET_PHONE_REGISTER_TIMER_COUNT: (state, data) => {
    state.phoneRegister.count = data;
  },
  SET_PHONE_REGISTER_TIMER_LOCK: (state, data) => {
    state.phoneRegister.lock = data;
  },
  SET_PHONE_REGISTER_TIMER_TEMP_DATA: (state, data) => {
    state.phoneRegister.tempData = data;
  },
  SET_PHONE_REGISTER_TIMER_FIRST_CLICK: (state, data) => {
    state.phoneRegister.firstClick = data;
  },
  SET_PHONE_REGISTER_TIMER_DATE_TIME: (state, data) => {
    state.phoneRegister.dateTime = data;
  },
  SET_UPDATE_MEMBER_INFO_TIMER_COUNT: (state, data) => {
    state.updateMemberInfo.count = data;
  },
  SET_UPDATE_MEMBER_INFO_TIMER_LOCK: (state, data) => {
    state.updateMemberInfo.lock = data;
  },
  SET_UPDATE_MEMBER_INFO_TIMER_TEMP_DATA: (state, data) => {
    state.updateMemberInfo.tempData = data;
  },
  SET_UPDATE_MEMBER_INFO_TIMER_FIRST_CLICK: (state, data) => {
    state.updateMemberInfo.firstClick = data;
  },
  SET_UPDATE_MEMBER_INFO_TIMER_DATE_TIME: (state, data) => {
    state.updateMemberInfo.dateTime = data;
  },
  RESET_ALL: (state) => {
    state.count = 180;
    state.lock = false;
    state.tempData = null;
    state.firstClick = false;
    state.dateTime = null;
    state.phoneRegister = {
      count: 180,
      lock: false,
      tempData: null,
      firstClick: false,
      dateTime: null,
    };
    state.updateMemberInfo = {
      count: 180,
      lock: false,
      tempData: null,
      firstClick: false,
      dateTime: null,
    };
  },
};

const actions = {
  setTimerCount({ commit }, count) {
    commit('SET_TIMER_COUNT', count);
  },
  setTimerLock({ commit }, lock) {
    commit('SET_TIMER_LOCK', lock);
  },
  setTimerTempData({ commit }, Data) {
    commit('SET_TIMER_TEMP_DATA', Data);
  },
  setFirstClick({ commit }, firstClick) {
    commit('SET_FIRST_CLICK', firstClick);
  },
  setTimerDateTime({ commit }, dateTime) {
    commit('SET_TIMER_DATE_TIME', dateTime);
  },
  setPhoneRegisterTimerCount({ commit }, count) {
    commit('SET_PHONE_REGISTER_TIMER_COUNT', count);
  },
  setPhoneRegisterTimerLock({ commit }, lock) {
    commit('SET_PHONE_REGISTER_TIMER_LOCK', lock);
  },
  setPhoneRegisterTimerTempData({ commit }, Data) {
    commit('SET_PHONE_REGISTER_TIMER_TEMP_DATA', Data);
  },
  setPhoneRegisterTimerFirstClick({ commit }, firstClick) {
    commit('SET_PHONE_REGISTER_TIMER_FIRST_CLICK', firstClick);
  },
  setPhoneRegisterTimerDateTime({ commit }, dateTime) {
    commit('SET_PHONE_REGISTER_TIMER_DATE_TIME', dateTime);
  },
  setUpdateMemberInfoTimerCount({ commit }, count) {
    commit('SET_UPDATE_MEMBER_INFO_TIMER_COUNT', count);
  },
  setUpdateMemberInfoTimerLock({ commit }, lock) {
    commit('SET_UPDATE_MEMBER_INFO_TIMER_LOCK', lock);
  },
  setUpdateMemberInfoTimerTempData({ commit }, Data) {
    commit('SET_UPDATE_MEMBER_INFO_TIMER_TEMP_DATA', Data);
  },
  setUpdateMemberInfoTimerFirstClick({ commit }, firstClick) {
    commit('SET_UPDATE_MEMBER_INFO_TIMER_FIRST_CLICK', firstClick);
  },
  setUpdateMemberInfoTimerDateTime({ commit }, dateTime) {
    commit('SET_UPDATE_MEMBER_INFO_TIMER_DATE_TIME', dateTime);
  },
  resetAll({ commit }) {
    commit('RESET_ALL');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
