import Big from 'big.js/big.mjs';
import API from '~/api-tc/memberRank';
import CONSTANT_MEMBER_RANK from '~/utils/constant/memberRank';

const state = () => ({
  memberRankList: [],
  memberRankMapping: {},
  memberAnalysis: {
    betAmount: {
      amount: 0,
      lastUpdateTime: '',
    },
    depositTimes: 0,
    depositAmount: 0,
    firstDepositTime: '',
    firstDepositAmount: 0,
    withdrawTimes: 0,
    withdrawAmount: '',
  },
  initRank: {
    start: 0,
    end: 1,
    depositTimes: 0,
    depositAmount: 0,
    totalBettingAmount: 0,
    level: 0,
  },
  memberRankAccumulationDay: {
    isAccumulationDay: false,
    stateTime: '',
    endTime: '',
  },
});

const mutations = {
  SET_MEMBER_RANK_LIST(state, data) {
    state.memberRankList = data.map((rank) => ({
      ...rank,
      depositAmount: Number(rank.depositAmount),
      totalBettingAmount: Number(rank.totalBettingAmount),
    }));
  },
  SET_MEMBER_RANK_MAPPING(state, data) {
    state.memberRankMapping = data.reduce((result, rank) => {
      result[rank.level] = rank.name;
      // result[rank.name] = rank.level;
      return result;
    }, {});
  },
  SET_MEMBER_ANALYSIS(state, data) {
    state.memberAnalysis = data;
  },
  SET_MEMBER_RANK_ACCUMULATION_DAY(state, data) {
    state.memberRankAccumulationDay = data;
  },
};

const actions = {
  async getMemberRank({ commit }) {
    const res = await API.getMemberRankList().catch((err) => console.log(err));
    if (res.isSuccess) {
      commit('SET_MEMBER_RANK_LIST', res.data);
      commit('SET_MEMBER_RANK_MAPPING', res.data);
    }
    return res;
  },
  async getMemberAnalysis({ commit }) {
    const res = await API.getMemberAnalysis().catch((err) => console.log(err));
    if (res.isSuccess) {
      const formatData = {
        ...res.data,
        betAmount: {
          ...res.data.betAmount,
          amount: Number(res.data.betAmount.amount),
        },
        depositAmount: Number(res.data.depositAmount),
        depositTimes: Number(res.data.depositTimes),
        firstDepositAmount: Number(res.data.firstDepositAmount),
        withdrawAmount: Number(res.data.withdrawAmount),
      };
      commit('SET_MEMBER_ANALYSIS', formatData);
    }
  },
  async upgradeMemberRank({ dispatch }) {
    const res = await API.upgradeMemberRank().catch((err) => console.log(err));
    if (res?.isSuccess) {
      await dispatch('member/getMemberData', undefined, { root: true });
      await dispatch('getMemberRank');
    }
    return res;
  },
  async getMemberRankAccumulationDay({ commit }) {
    const res = await API.getMemberRankAccumulationDay().catch((err) => console.log(err));
    if (res?.isSuccess) {
      commit('SET_MEMBER_RANK_ACCUMULATION_DAY', res.data);
    }
  },
};

const getters = {
  rankInfo: (state, _getters, rootState) => {
    const data = rootState.member.memberData;
    return {
      level: data?.rankLevel?.value || 0,
      type: data?.rankLevel?.value || 0,
      name: data?.rankName?.value || '',
      id: state.memberRankList.find((rank) => rank.level === data?.rankLevel?.value)?.id ?? 0,
    };
  },
  nextRankInfo: (state, getters) => {
    if (state.memberRankList.length === 0) return state.initRank;
    return state.memberRankList[getters.rankInfo.level] || state.initRank;
  },
  isTopRank: (state, getters) => getters.rankInfo.level >= state.memberRankList.length,
  depositTimesProgress: (state, getters) => {
    if (getters.isTopRank) return 100;

    const current = state.memberAnalysis.depositTimes;
    const target = getters.nextRankInfo.depositTimes;
    if (+target <= 0) return 100;

    return Math.min(100, new Big(current).div(new Big(target)).times(100));
  },
  depositAmountProgress: (state, getters) => {
    if (getters.isTopRank) return 100;

    const current = state.memberAnalysis.depositAmount;
    const target = getters.nextRankInfo.depositAmount;
    if (+target <= 0) return 100;
    return Math.min(100, new Big(current).div(new Big(target)).times(100).toString());
  },
  totalBetAmountProgress: (state, getters) => {
    if (getters.isTopRank) return 100;

    const current = state.memberAnalysis.betAmount.amount;
    const target = getters.nextRankInfo.totalBettingAmount;
    if (+target <= 0) return 100;
    return Math.min(100, new Big(current).div(new Big(target)).times(100).toString());
  },
  currentVIPImage: (_state, _getters, rootState) => {
    const data = rootState.member.memberData;
    const currentLevel = data?.rankLevel?.value || 1;
    const find = _state.memberRankList.find((lv) => lv.level === currentLevel);

    return find?.imgUrl;
  },
  isLevel2RequiredBankInfo: (state) => state.memberRankList
    .find((item) => item.level === 2)
    ?.validation?.some(
      (condition) => condition === CONSTANT_MEMBER_RANK.validation.PAYMENT,
    ),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
