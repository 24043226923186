import { detectDevice } from '~/utils/detectDevice';
import API from '../../api';

const state = {
  tn: '',
  ac: '',
  device: '',
  DeviceID: '',
  deviceType: detectDevice(),
  Name: '',
  IDNumber: '',
  MemberInfoData: {
    Account: '',
    AccountBind: true,
    Birth: '',
    CellPhoneBind: true,
    CreateTime: '',
    Email: '',
    Exp: 0,
    FbBind: false,
    IdNumber: '',
    Level: 0,
    LineBind: false,
    Name: '',
    NickName: '',
    PhoneNumber: '',
    Wallet: 0,
    ReferrerBind: false,
    LocationID: 1,
    CurrencyID: 1,
    CurrencyRate: 1,
    GameAccount: '',
    Referre: '',
    Type: null,
    CurrencyName: 'THB',
    Progress: 0,
  },
  FBInfo: {},
  RefLinkData: {
    ac: '',
    m: '',
    cmd: '',
  },
  isMemberInfoLoading: false,
  isWalletLoading: false,
  isLotteryLoading: false,
  isDiamondShopLoading: false,
  HasLottery: false,
  HasDiamondShop: false,
  DiamondAmount: 0,
  IsBind: {
    data: {},
    isLoading: false,
  },
  InviteInfo: {
    isLoadind: false,
    data: {},
  },
  ReferrerRecords: {
    isLoading: false,
    data: {
      1: {},
      2: {},
    },
  },
  HasPromoCode: {
    data: {},
    isLoading: false,
  },
};

const getters = {
  InviteInfo: (userState) => `${userState.InviteInfo.data.Message ? userState.InviteInfo.data.Message : ''} ${userState.InviteInfo.data.Link}`,
  ReferrerRecords: (userState) => userState.ReferrerRecords,
  HasPromoCode: (userState) => userState.HasPromoCode,
  deviceType: (userState) => userState.deviceType,
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.tn = token;
  },
  SET_DEVICE: (state, device) => {
    state.device = device;
  },
  SET_MEMBERINFO: (state, memberInfo) => {
    state.MemberInfoData = memberInfo;
  },
  SET_FBINFO: (state, FBInfo) => {
    state.FBInfo = FBInfo;
  },
  SET_AGENT_CODE: (state, AgentCode) => {
    state.ac = AgentCode;
  },
  SET_DEVICE_ID: (state, DeviceID) => {
    state.DeviceID = DeviceID;
  },
  SET_REFLINKDATA: (state, RefLinkData) => {
    state.RefLinkData = RefLinkData;
  },
  SET_ISMEMBERINFOLOADING: (state, data) => {
    state.isMemberInfoLoading = data;
  },
  SET_ISWALLETLOADING: (state, data) => {
    state.isWalletLoading = data;
  },
  SET_ISLOTTERYLOADING: (state, data) => {
    state.isLotteryLoading = data;
  },
  SET_HASLOTTERY: (state, data) => {
    state.HasLottery = data;
  },
  SET_ISDIAMONDSHOPLOADING: (state, data) => {
    state.isDiamondShopLoading = data;
  },
  SET_HASDIAMONDSHOP: (state, data) => {
    state.HasDiamondShop = data;
  },
  SET_DIAMONDAMOUNT: (state, data) => {
    state.DiamondAmount = data;
  },
  SET_ISCANPROMOTIONFINISHLOADING: (state, data) => {
    state.isCanPromotionFinishLoading = data;
  },
  SET_CANPROMOTIONFINISH: (state, data) => {
    state.CanPromotionFinish = data;
  },
  SET_ISBIND: (state, data) => {
    state.IsBind = data;
  },
  SET_INVITEINFO: (state, data) => {
    state.InviteInfo = data;
  },
  SET_REFERRERRECORDS: (state, data) => {
    state.ReferrerRecords = data;
  },
  SET_HASPROMOCODE: (state, data) => {
    state.HasPromoCode = data;
  },
};

const actions = {
  setToken({ commit }, token) {
    commit('SET_TOKEN', token);
  },
  setDevice({ commit }, device) {
    commit('SET_DEVICE', device);
  },
  setMemberInfo({ commit }, memberInfo) {
    commit('SET_MEMBERINFO', memberInfo);
  },
  setFBInfo({ commit }, FBInfo) {
    commit('SET_FBINFO', FBInfo);
  },
  async setAgentCode({ commit }, AgentCode) {
    commit('SET_AGENT_CODE', AgentCode);
  },
  setDeviceID({ commit }, DeviceID) {
    commit('SET_DEVICE_ID', DeviceID);
  },
  setRefLinkData({ commit }, RefLinkData) {
    commit('SET_REFLINKDATA', RefLinkData);
  },
  logout({ commit }) {
    commit('SET_TOKEN', '');
    commit('SET_MEMBERINFO', {});
    commit('SET_FBINFO', {});
    commit('SET_REFLINKDATA', {
      ac: '',
      m: '',
      cmd: '',
    });
  },
  async getMemberInfo({ commit, state }) {
    if (state.isMemberInfoLoading) {
      console.log('會員資訊讀取中, 已擋下');
      return;
    }
    commit('SET_ISMEMBERINFOLOADING', true);
    const { ErrorCode, Data } = await API.User.getInfo().catch((e) => {
      console.log(e);
      commit('SET_ISMEMBERINFOLOADING', false);
    });
    commit('SET_ISMEMBERINFOLOADING', false);
    if (ErrorCode === 0) {
      commit('SET_MEMBERINFO', Data);
    }
  },
  async getWallet({ commit, state }) {
    if (state.isWalletLoading) {
      console.log('錢包讀取中, 已擋下');
      return;
    }
    commit('SET_ISWALLETLOADING', true);
    const { ErrorCode, Data } = await API.User.GetWallet().catch((e) => {
      console.log(e);
      commit('SET_ISWALLETLOADING', false);
    });
    commit('SET_ISWALLETLOADING', false);
    if (ErrorCode === 0) {
      commit('SET_MEMBERINFO', {
        ...state.MemberInfoData,
        Wallet: Data?.Amount,
      });
      commit('SET_DIAMONDAMOUNT', Data?.DiamondAmount ? Data?.DiamondAmount : 0);
    }
  },
  async getHasLottery({ commit, state }) {
    if (state.isLotteryLoading) {
      console.log('抽獎讀取中, 已擋下');
      return;
    }
    commit('SET_ISLOTTERYLOADING', true);
    const { ErrorCode, Data } = await API.Activity.GetIsLotteryOpen().catch((e) => {
      console.log(e);
      commit('SET_ISLOTTERYLOADING', false);
    });
    commit('SET_ISLOTTERYLOADING', false);
    if (ErrorCode === 0) {
      commit('SET_HASLOTTERY', Data.Open);
    }
  },
  async getHasDiamondShop({ commit, state }) {
    if (state.isDiamondShopLoading) {
      console.log('鑽石商城讀取中, 已擋下');
      return;
    }
    commit('SET_ISDIAMONDSHOPLOADING', true);
    const { ErrorCode, Data } = await API.Activity.GetIsDiamondShopOpen().catch((e) => {
      console.log(e);
      commit('SET_ISDIAMONDSHOPLOADING', false);
    });
    commit('SET_ISDIAMONDSHOPLOADING', false);
    if (ErrorCode === 0) {
      commit('SET_HASDIAMONDSHOP', Data.Open);
    } else {
      commit('SET_HASDIAMONDSHOP', false);
    }
  },
  async getIsBind({ commit, state }) {
    if (state.IsBind.isLoading) {
      console.log('getBindInfo 讀取中');
      return;
    }
    commit('SET_ISBIND', { ...state.IsBind, isLoading: true });
    const res = await API.User.BindCheck().catch((e) => {
      console.log(e);
      commit('SET_ISBIND', { ...state.IsBind, isLoading: false });
    });
    commit('SET_ISBIND', { ...state.IsBind, isLoading: false });
    if (res?.ErrorCode === 0) {
      commit('SET_ISBIND', { data: res.Data, isLoading: false });
    } else {
      commit('SET_ISBIND', { ...state.IsBind, isLoading: false });
    }
  },
  async getInviteInfo({ commit, state }) {
    if (state.InviteInfo.isLoading) {
      console.log('Invite Info 讀取中');
      return;
    }
    commit('SET_INVITEINFO', { ...state.InviteInfo, isLoading: true });
    const res = await API.User.SharedReferrer().catch((e) => {
      console.log(e);
      commit('SET_INVITEINFO', { ...state.InviteInfo, isLoading: false });
    });
    commit('SET_INVITEINFO', { ...state.InviteInfo, isLoading: false });
    if (res?.ErrorCode === 0) {
      commit('SET_INVITEINFO', { data: res.Data, isLoading: false });
    } else {
      commit('SET_INVITEINFO', { ...state.InviteInfo, isLoading: false });
    }
  },
  async getReferrerRecords({ commit, state }) {
    return new Promise(async (resolve, reject) => {
      if (state.ReferrerRecords.isLoading) {
        console.log('推薦人紀錄 讀取中');
        reject(new Error('ReferrerRecords is loading'));
      }
      commit('SET_REFERRERRECORDS', { ...state.ReferrerRecords, isLoading: true });
      const res = await Promise.all([
        API.User.getReferrerRecord({ Period: 1 }), API.User.getReferrerRecord({ Period: 2 }),
      ]).catch((err) => {
        commit('SET_REFERRERRECORDS', { ...state.ReferrerRecords, isLoading: false });
        reject(err);
      });
      commit('SET_REFERRERRECORDS', { ...state.ReferrerRecords, isLoading: false });
      const [res1, res2] = res;
      const data = {
        1: res1?.ErrorCode === 0 ? res1.Data : {},
        2: res2?.ErrorCode === 0 ? res2.Data : {},
      };
      commit('SET_REFERRERRECORDS', { data, isLoading: false });
      resolve(true);
    });
  },
  async getHasPromoCode({ commit, state }) {
    if (state.HasPromoCode.isLoading) {
      console.log('Promo Code 讀取中');
      return;
    }
    commit('SET_HASPROMOCODE', { ...state.HasPromoCode, isLoading: true });
    const res = await API.User.getHasPromoCode().catch((e) => {
      console.log(e);
      commit('SET_HASPROMOCODE', { ...state.HasPromoCode, isLoading: false });
    });
    commit('SET_HASPROMOCODE', { ...state.HasPromoCode, isLoading: false });
    if (res?.ErrorCode === 0) {
      commit('SET_HASPROMOCODE', { data: res.Data, isLoading: false });
    } else {
      commit('SET_HASPROMOCODE', { ...state.HasPromoCode, isLoading: false });
    }
  },
  getAllData({ dispatch }) {
    dispatch('getHasLottery');
    dispatch('getHasDiamondShop');
    dispatch('getMemberInfo');
    dispatch('getIsBind');
    dispatch('getInviteInfo');
    dispatch('getReferrerRecords');
    dispatch('getHasPromoCode');
  },
  resetLoading({ commit }) {
    commit('SET_ISMEMBERINFOLOADING', false);
    commit('SET_ISWALLETLOADING', false);
    commit('SET_ISLOTTERYLOADING', false);
    commit('SET_ISDIAMONDSHOPLOADING', false);
    commit('SET_ISBIND', { ...state.IsBind, isLoading: false });
    commit('SET_INVITEINFO', { ...state.InviteInfo, isLoading: false });
    commit('SET_REFERRERRECORDS', { ...state.ReferrerRecords, isLoading: false });
    commit('SET_HASPROMOCODE', { ...state.HasPromoCode, isLoading: false });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
