import { reduce } from 'lodash';
import {
  getActivityData,
  getMissionList,
  getActivityReward,
  getMissionReward,
  getAllMissionReward,
  getTaskHistory,
} from '~/api-tc/mission';

const state = () => ({
  // 任務列表
  missionList: [],
  // 會員活躍度
  activityData: {
    activity: 0,
    activityList: [],
  },
  rewardBox: {},
  // 歷史任務
  taskHistory: {
    count: 0,
    records: [],
  },
});

const getters = {
  unreceivedMissionCount: (state) => {
    const unreceivedMissions = state.missionList.filter(mission => !mission.received && mission.current >= mission.required).length;
    const unreceivedActivities = state.activityData.activityList.filter(activity => !activity.opened && state.activityData.activity >= activity.value).length;
    return unreceivedMissions + unreceivedActivities;
  },
};
const mutations = {
  setActivityData(state, activityData) {
    state.activityData = activityData;
  },
  setMissionList(state, missionList) {
    state.missionList = missionList;
  },
  setRewardBox(state, rewardBox) {
    state.rewardBox = rewardBox;
  },
  setTaskHistory(state, taskHistory) {
    state.taskHistory = taskHistory;
  },
  setMissionReceived(state, promotionId) {
    const misson = state.missionList.find((m) => m.promotionId === promotionId);
    if (misson) {
      misson.received = true;
    }
  },
};
const actions = {
  async actionActivityData({ commit }) {
    const { data } = await getActivityData();
    commit('setActivityData', data);
  },
  async actionMissionList({ commit }) {
    const { data } = await getMissionList();
    commit('setMissionList', data);
  },
  async actionActivityReward({ commit }, postData) {
    const { data } = await getActivityReward(postData);
    commit('setRewardBox', data);
  },
  async actionMissionReward({ commit }, postData) {
    const { data } = await getMissionReward(postData);
    commit('setRewardBox', data);
  },
  async actionAllMissionReward({ commit }, postData) {
    const { data } = await getAllMissionReward(postData);
    commit('setRewardBox', data);
  },
  async actionTaskHistory({ commit }, postData) {
    const { data } = await getTaskHistory(postData);
    commit('setTaskHistory', data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
