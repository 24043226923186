/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import Big from 'big.js/big.mjs';
import { isEqual, pick } from 'lodash';
import API from '~/api-tc/member';
import { percent } from '~/filters';
import common from '~/utils/common';
import memberConstant from '~/utils/constant/member';
import commonConstant from '~/utils/constant/common';
import loginMethodsConstant from '~/utils/constant/loginMethods';
import { cryptoUtil } from '~/utils/crypto';

const state = () => ({
  memberInfo: {},
  memberData: {},
  token: null,
  inviteInfo: {},
  memberBankList: [],
  sectionSerial: null,
  snsBindList: [],
  memberBetLevel: {
    level: 1,
    amount: 0,
    giftAmount: 0,
    giftFrequency: 0,
    remainingGiftCount: 0,
  },
  passwordType: 1,
  loginMethod: null,
  guest: {},
});

const mutations = {
  SET_MEMBERINFO(state, data) {
    state.memberInfo = data;
  },
  SET_MEMBER_DATA(state, data) {
    state.memberData = data;
  },
  SET_TOKEN(state, data) {
    state.token = data;
  },
  SET_INVITE_INFO(state, data) {
    state.inviteInfo = data;
  },
  SET_MEMBER_BANK_LIST(state, data) {
    state.memberBankList = data;
  },
  SET_SECTION_SERIAL(state, data) {
    state.sectionSerial = data;
  },
  SET_SNS_BIND_LIST(state, data) {
    state.snsBindList = data;
  },
  SET_MEMBER_BET_LEVEL(state, data) {
    state.memberBetLevel.level = data?.betLevel ?? 1;
    state.memberBetLevel.amount = data?.betAmount ?? 0;
    state.memberBetLevel.giftAmount = data?.giftAmount ?? 0;
    state.memberBetLevel.giftFrequency = data?.giftFrequency ?? 0;
    state.memberBetLevel.remainingGiftCount = data?.remainingGiftCount ?? 0;
  },
  SET_PASSWORD_TYPE(state, data) {
    state.passwordType = data;
  },
  SET_LOGIN_METHOD(state, data) {
    state.loginMethod = data;
  },
  SET_GUEST_INFO(state, payload) {
    state.guest = payload;
  },
};

const actions = {
  async getMemberData({ commit, state }) {
    const res = await API.getMemberData().catch((err) => console.log(err));
    if (res.isSuccess) {
      commit('SET_MEMBER_DATA', res.data);
    }
    return res;
  },
  async getInviteInfo({ commit }) {
    const res = await API.getInviteLinks().catch((err) => console.log(err));
    if (res.isSuccess) {
      commit('SET_INVITE_INFO', res.data || {});
    }
  },
  async getMemberBankList({ commit }) {
    const res = await API.getMemberBankList().catch((err) => console.log(err));
    if (res.isSuccess) {
      commit('SET_MEMBER_BANK_LIST', res.data || []);
    }
  },
  async getSNSBindList({ commit }) {
    const res = await API.getSNSBind().catch((err) => console.log(err));
    if (res.isSuccess) {
      commit('SET_SNS_BIND_LIST', res.data || []);
    }
  },
  actionVerifyOTP: (context, opts) => API.verifyOTP(opts),
  actionCheckReferee: (context, opts) => API.checkReferee(opts),
  setMemberInfo({ commit }, data) {
    commit('SET_MEMBERINFO', data);
  },
  setToken({ commit }, data) {
    commit('SET_TOKEN', data);
  },
  logout({ commit }, autoLogout = true) {
    commit('SET_MEMBER_DATA', {});
    commit('SET_MEMBERINFO', {});
    commit('SET_TOKEN', null);
    // if (!autoLogout) {
    //   commit('SET_GUEST_INFO', {});
    // }
  },
  setSectionSerial({ commit }, sectionSerial) {
    commit('SET_SECTION_SERIAL', sectionSerial);
  },
  actionCheckUsername: (context, opts) => API.checkUsername(opts),
  actionCheckMemberMid: (_, payload) => API.checkMemberMid(payload),
  actionGetMemberBetLevel: async ({ commit, state }) => {
    const { data } = await API.getMemberBetLevel({
      memberId: state.memberInfo.memberId,
    });
    commit('SET_MEMBER_BET_LEVEL', data);
  },
  actionSetPasswordType: ({ commit }, data) => {
    commit('SET_PASSWORD_TYPE', data);
  },
  actionSetLoginMethod: ({ commit }, data) => commit('SET_LOGIN_METHOD', data),
  actionGuestLogin: async ({ commit, state, rootGetters }) => {
    commit('app/SET_ISLOADING', true, { root: true });
    const isGuest = !!(state.guest.username && state.guest.password);
    const req = isGuest ? API.login : API.guestLogin;
    const formData = isGuest ? {
      username: state.guest.username,
      pwd: cryptoUtil.decrypt(state.guest.password),
      deviceType: rootGetters.deviceType,
    } : {
      deviceType: commonConstant.deviceType.MOBILE,
      registerMethod: memberConstant.registerMethod.MEM_QUICK_SIGN_UP,
      pid: common.getLocalStorageValue('pid'),
    };
    const result = await req(formData).catch(() => {
      commit('app/SET_ISLOADING', false, { root: true });
    });
    commit('app/SET_ISLOADING', false, { root: true });
    if (!isGuest) {
      const { username, password } = result.data.user;
      commit('SET_GUEST_INFO', { username, password: cryptoUtil.encrypt(password) });
    }
    common.setLocalStorageValue('Authorization', result.data.token);
    common.setLocalStorageValue('RefreshToken', result.data.refreshToken);
    commit('SET_MEMBERINFO', result.data.user);
    commit('SET_TOKEN', result.data.token);
    return result;
  },
};

const getters = {
  token: (state) => state.token,
  memberData: (state) => state.memberData,
  memberInfo: (state) => state.memberInfo,
  memberBankList: (state) => state.memberBankList,
  snsBindList: (state) => state.snsBindList,
  memberBetLevelProgressDetail: (state, _, _rootState, rootGetters) => {
    const betLevelSetting = rootGetters['initData/getInitDataByPath'](
      'memberBetLevelSetting',
    );
    if (betLevelSetting.length === 0) {
      return {
        current: state.memberBetLevel.amount,
        dest: 0,
      };
    }
    let prevSetting = { level: 0, amount: 0 };

    for (const setting of betLevelSetting) {
      if (setting.level > state.memberBetLevel.level) {
        break;
      }
      prevSetting.level = setting.level;
      prevSetting.amount = setting.upgradeBetAmount;
    }

    return {
      current: state.memberBetLevel.amount,
      dest: prevSetting.amount,
    };
  },
  memberBetLevelProgress: (state, _, _rootState, rootGetters) => {
    const betLevelSetting = rootGetters['initData/getInitDataByPath'](
      'memberBetLevelSetting',
    );
    if (betLevelSetting.length === 0) return 0;
    let prevSetting = { level: 0, amount: 0 };

    for (const setting of betLevelSetting) {
      if (setting.level > state.memberBetLevel.level) {
        break;
      }
      prevSetting.level = setting.level;
      prevSetting.amount = setting.upgradeBetAmount;
    }

    return percent(
      Math.min(
        1,
        new Big(state.memberBetLevel.amount)
          .div(prevSetting.amount),
      ),
    );
  },
  isNeedChangePassword(state) {
    return false;
    // return state.loginMethod === loginMethodsConstant.Phone;
  },
  isGuest: (state) => !!(state.guest.username && state.guest.password),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
