import orderBy from 'lodash/orderBy';
import { cloneDeep, isEmpty } from 'lodash';

import {
  getDailyReport,
  getMonthWinnerVideo,
  getTotalList,
  getFrontEndReport,
} from '~/api-tc/ranking';

import { getListFormat, countDailyWinner } from '~/utils/ranking';

const types = {
  SET_DAILY_REPORT: 'SET_DAILY_REPORT',
  SET_FRONTEND_REPORT: 'SET_FRONTEND_REPORT',
  SET_MONTH_WINNER_VIDEO: 'SET_MONTH_WINNER_VIDEO',
};

const state = {
  dailyReport: {
    creditRank: getListFormat(6, []),
    moneyRank: getListFormat(6, []),
  },
  monthWinnerVideo: null,
  current: {
    creditRank: {
      dailyRank: getListFormat(28, [], 7),
      weeklyRank: getListFormat(4, [], 1),
      monthlyRank: getListFormat(1, []),
    },
    moneyRank: {
      dailyRank: getListFormat(28, [], 7),
      weeklyRank: getListFormat(4, [], 1),
      monthlyRank: getListFormat(1, []),
    },
  },
  last: {
    creditRank: {
      dailyRank: getListFormat(28, [], 7),
      weeklyRank: getListFormat(4, [], 1),
      monthlyRank: getListFormat(1, []),
    },
    moneyRank: {
      dailyRank: getListFormat(28, [], 7),
      weeklyRank: getListFormat(4, [], 1),
      monthlyRank: getListFormat(1, []),
    },
  },
};

const mutations = {
  [types.SET_DAILY_REPORT](state, { data, actionType }) {
    state.dailyReport[actionType] = getListFormat(6, data);
  },
  [types.SET_FRONTEND_REPORT](state, { data, type, actionType }) {
    state[type][actionType] = {
      dailyRank: getListFormat(28, data.dailyRank, 7),
      weeklyRank: getListFormat(4, data.weeklyRank, 1),
      monthlyRank: getListFormat(1, data.monthlyRank),
    };
  },
  [types.SET_MONTH_WINNER_VIDEO](state, { data, actionType }) {
    state.monthWinnerVideo = data;
  },
};

const actions = {
  async getDailyReport({ commit }, request) {
    const { data } = await getDailyReport(request);
    commit(types.SET_DAILY_REPORT, {
      data,
      actionType: request.actionType,
    });
  },
  async getMonthWinnerVideo({ commit }, request) {
    const { data } = await getMonthWinnerVideo(request);
    commit(types.SET_MONTH_WINNER_VIDEO, {
      data,
      actionType: request.actionType,
    });
  },
  async getTotalReportList({ commit, dispatch }, request) {
    const { data } = await getTotalList(request);
    const dataLen = data.length;
    const currentIndex = data[dataLen - 1];
    const lastIndex = data[dataLen - 2] || null;
    dispatch('getFrontEndReport', {
      ...request,
      type: 'current',
      startIndex: currentIndex.startIndex,
      endIndex: currentIndex.endIndex,
    });

    if (!lastIndex) {
      commit(types.SET_FRONTEND_REPORT, {
        data: {
          dailyRank: [],
          weeklyRank: [],
          monthlyRank: [],
        },
        type: 'last',
        actionType: request.actionType,
      });
      return;
    }
    dispatch('getFrontEndReport', {
      ...request,
      type: 'last',
      startIndex: lastIndex.startIndex,
      endIndex: lastIndex.endIndex,
    });
  },
  async getFrontEndReport({ commit }, request) {
    const { type } = request;
    const { data } = await getFrontEndReport(request);
    commit(
      types.SET_FRONTEND_REPORT,
      {
        data,
        type,
        actionType: request.actionType,
      },
    );
  },
};

const getters = {
  getDailyWinner: (state) => (type) => (
    // OQA-1749 [WEB][系統]首頁龍虎榜：抓取龍虎榜單邏輯須修改
    countDailyWinner(state.dailyReport[type])
  ),
  dragonDailyWinner: (state, getters) => (
    getters.getDailyWinner('moneyRank') || { isEmpty: true }
  ),
  tigerDailyWinner: (state, getters) => (
    getters.getDailyWinner('creditRank') || { isEmpty: true }
  ),
  getWinnerCount: (state) => (type) => {
    const creditWinner = state[type].creditRank.dailyRank.reduce((result, item) => {
      if (item.isEmpty) { return result; }
      const { memberId } = item;
      if (!result[memberId]) {
        result[memberId] = {
          name: item.username,
          score: 1,
          memberId,
        };
      } else {
        result[memberId].score += 1;
      }

      return result;
    }, {});
    const moneyWinner = state[type].moneyRank.dailyRank.reduce((result, item) => {
      if (item.isEmpty) { return result; }
      const { memberId } = item;
      if (!result[memberId]) {
        result[memberId] = {
          name: item.username,
          score: 1,
          memberId,
        };
      } else {
        result[memberId].score += 1;
      }

      return result;
    }, {});
    return {
      moneyWinner: orderBy(moneyWinner, ['score'], ['desc']),
      creditWinner: orderBy(creditWinner, ['score'], ['desc']),
    };
  },
  currentWinner: (state, getters) => (getters.getWinnerCount('current')),
  lastWinner: (state, getters) => {
    const winner = getters.getWinnerCount('last');
    console.log(winner);
    let moneyWinner = [];
    let creditWinner = [];
    if (winner.moneyWinner.length > 0) {
      const winnerMoneyScore = winner.moneyWinner[0].score;
      moneyWinner = winner.moneyWinner.filter((item) => item.score === winnerMoneyScore);
    }
    if (winner.creditWinner.length > 0) {
      const winnerCreditScore = winner.creditWinner[0].score;
      creditWinner = winner.creditWinner.filter((item) => item.score === winnerCreditScore);
    }
    return {
      moneyWinner,
      creditWinner,
    };
  },
  getChampList: (state) => (type) => {
    const defaultData = [
      {
        weekChamp: null,
        dailyChamp: [],
      },
      {
        weekChamp: null,
        dailyChamp: [],
      },
      {
        weekChamp: null,
        dailyChamp: [],
      },
      {
        weekChamp: null,
        dailyChamp: [],
      },
    ];
    const result = {
      moneyRank: cloneDeep(defaultData),
      creditRank: cloneDeep(defaultData),
    };
    Object.entries(state[type]).map(([key, value]) => {
      const { weeklyRank, dailyRank } = value;
      dailyRank.forEach((dailyData) => {
        const week = dailyData.weekNumber - 1;
        dailyData.imgUrl = dailyData.imageData?.imageMap?.['500x500'] ?? dailyData?.imageData?.imageUrl;
        result[key][week].dailyChamp.push(dailyData);
      });
      weeklyRank.forEach((weeklyData) => {
        const week = weeklyData.weekNumber - 1;
        weeklyData.imgUrl = weeklyData?.imageData?.imageMap?.['500x500'] ?? weeklyData?.imageData?.imageUrl;
        result[key][week].weekChamp = weeklyData;
      });
    });
    return result;
  },
  lastChampList: (state, getters) => (getters.getChampList('last')),
  currentChampList: (state, getters) => (getters.getChampList('current')),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
