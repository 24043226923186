import Big from 'big.js/big.mjs';
import { subDays } from 'date-fns';
import { Message } from 'element-ui';
import common from '~/utils/common';
import loadingHTML from '~/utils/constant/loadingHTML';
import {
  getPaymentMethods,
  getPaymentCategory,
  getPaymentBankList,
  getManualDepositAccountList,
  getAutoCashInAccountList,
  addManualDepositOrder,
  getOnlinePayment,
  addWithdrawOrder,
  getRewardList,
  sendApplyRedeem,
  getRedeemList,
  getPointRedeemControl,
  getFinancialRecord,
  getFinancialAllRecord,
  getRecoupmentRecord,
  getPaymentMerchantList,
  onlinePaymentConfirm,
  getWithdrawSetting,
  getAllPlatMoney,
  transPlatformMoney,
  transAllPlatformMoney,
  getPromotionWallet,
  googlePlayBilling,
  applePayBilling,
} from '~/api-tc/financial';
import {
  getMailList,
  updateMail,
} from '~/api-tc/mail';

import constant from '~/utils/constant';
import commonConstants from '~/utils/constant/common';
import mailConstants from '~/utils/constant/mail';
import sortPlatformFn from '~/utils/sortPlatform';

const {
  payment: {
    type: {
      WITHDRAW: PAYMENT_TYPE_WITHDRAW, // 4
    },
    device: {
      ALL: PAYMENT_DEVICE_ALL,
      PC: PAYMENT_DEVICE_PC,
      APP: PAYMENT_DEVICE_APP,
    },
  },
  record: {
    type: {
      DEPOSIT: RECORD_TYPE_DEPOSIT,
      RECOUPMENT: RECORD_TYPE_RECOUPMENT,
      WITHDRAW: RECORD_TYPE_WITHDRAW,
    },
  },
} = constant.financial;

const {
  status: {
    ENABLED: STATUS_ENABLED,
  },
  deviceType: {
    PC: DEVICE_TYPE_PC,
    MOBILE: DEVICE_TYPE_MOBILE,
  },
} = constant.common;

const { DATE_LIMIT_RANGE } = constant.report;

const initSearchFilter = () => ({
  limit: {
    startIndex: 0,
    pageSize: 6,
  },
  sorts: [{
    columnName: 'addTime',
    orderType: 'desc',
  }],
});

const initRecordQueryData = (tradeType) => {
  const endTime = new Date();
  return {
    limit: {
      startIndex: 0,
      pageSize: 10,
    },
    sorts: [
      {
        columnName: 'addTime',
        orderType: 'desc',
      },
    ],
    endTime,
    startTime: subDays(endTime, DATE_LIMIT_RANGE),
    tradeType,
  };
};

const INIT_WALLET_DATA = () => ({
  cashWallet: { /* 主錢包資訊 */
    balance: 0,
    platform: [],
    total: 0,
  },
  promoteWallet: { /* 活動錢包資訊 */
    balance: 0,
    platform: [],
    total: 0,
  },
  totalAmount: 0,
  rewardPoint: 0,
});

// 判斷型別
const isType = (type) => (obj) => Object.prototype.toString.call(obj) == `[object ${type}]`;
const isObject = isType('Object');
const isArray = isType('Array');

const state = () => ({
  paymentCategory: [],
  paymentContentList: [],
  paymentBankList: [], // Bank || BankCode payment method
  manualDepositReceiptAccount: [],
  autoCashInReceiptAccount: [],
  withdrawSetting: {
    maxAmount: 0,
    minAmount: 0,
    status: STATUS_ENABLED,
    memo: '',
  },
  searchFilter: initSearchFilter(),
  rewardDataSource: {},
  rewardList: [],
  rewardListTotalPage: 1,
  redeemList: {
    isLoading: false,
    data: [],
  },
  moreRedeem: true,
  moreReward: true,
  queryLoading: false,
  searchTime: {},
  pointRedeemControl: {},
  depositRecord: {
    isLoading: false,
    data: [],
  },
  recoupmentRecord: {
    isLoading: false,
    data: [],
  },
  withdrawRecord: {
    isLoading: false,
    data: [],
  },
  paymentMerchantList: [],
  paymentMerchantIndex: 0,
  wallet: INIT_WALLET_DATA(),
  isOnlinePaymentTriggered: false,
  depositNotify: [],
});

const actions = {
  // 金流分類 paymentCategory
  actionQueryPaymentCategory({ commit }) {
    return getPaymentCategory()
      .then(({ data }) => {
        commit('setPaymentCategory', data);
      });
  },
  actionQueryPaymentContent({ rootState, commit, dispatch }, filter) {
    const deviceTypes = [PAYMENT_DEVICE_ALL, rootState.isNativeApp ? PAYMENT_DEVICE_APP : PAYMENT_DEVICE_PC];
    return getPaymentMethods({
      deviceTypes,
      status: STATUS_ENABLED,
      ...filter,
    })
      .then(({ data }) => {
        if (data.priorityType === constant.financial.deposit.merchant.priorityType.CUSTOM) {
          dispatch('actionQueryMerchantList', { paymentCategoryId: data.paymentCategoryId });
        }
        commit('setPaymentContentList', data);
      });
  },
  actionQueryMerchantList({ commit }, data) {
    return getPaymentMerchantList(data)
      .then(({ data }) => {
        commit('resetPaymentMerchantIndex');
        commit('setPaymentMerchantList', data);
      })
      .catch(() => {
        commit('setPaymentMerchantList', []);
      });
  },
  actionGetManualDepositReceiptAccount({ commit }) {
    return getManualDepositAccountList()
      .then(({ data }) => {
        commit('setManualDepositReceiptAccount', data);
      });
  },
  actionGetAutoCashInReceiptAccount({ commit }) {
    return getAutoCashInAccountList()
      .then(({ data }) => {
        commit('setAutoCashInReceiptAccount', data);
      });
  },
  // 提交手工存款單
  actionAddManualDepositOrder(store, data) {
    return addManualDepositOrder(data);
  },
  actionWithdrawSetting({ commit }) {
    return getWithdrawSetting()
      .then(({ data }) => {
        commit('setWithdrawSetting', data);
      });
  },
  actionWithdraw({ rootState }, postData) {
    return addWithdrawOrder(Object.assign(postData, { deviceType: rootState.isNativeApp ? DEVICE_TYPE_MOBILE : DEVICE_TYPE_PC }));
  },
  // 此 online function 版本已經離當初設計太遠 如果有需要要整個重構過 TC-2418
  actionOnlinePayment({
    rootState, commit, dispatch, getters, state,
  }, payload) {
    let windowPop;
    let { paymentMethodName, ...postData } = payload;
    if (getters.isCustomMerchantPriority) {
      Object.assign(postData, getters.getCurrentMerchantData());
    }
    windowPop = window.open();
    const html = loadingHTML.replace('logoSrc', rootState.initData.appLogoPicture[0]?.imgUrl);
    windowPop.document.body.innerHTML = html;

    return getOnlinePayment(Object.assign(postData, {
      mobileType: navigator.userAgent.indexOf('OS') ? DEVICE_TYPE_MOBILE : DEVICE_TYPE_PC,
      deviceType: rootState.isNativeApp ? DEVICE_TYPE_MOBILE : DEVICE_TYPE_PC,
      paymentCategoryId: payload.paymentCategoryId,
    }))
      .then(({ data }) => {
        commit('resetPaymentMerchantIndex');
        commit('setIsOnlinePaymentTriggered', true);
        const qrcodeProperty = Object.prototype.hasOwnProperty.call(data, 'qrcode');
        const urlProperty = Object.prototype.hasOwnProperty.call(data, 'url');
        const htmlProperty = Object.prototype.hasOwnProperty.call(data, 'html');
        const requireFieldsProperty = Object.prototype.hasOwnProperty.call(data, 'requireFields');
        if (qrcodeProperty) {
          if (!rootState.isNativeApp) windowPop.close();
          // TODO: 目前 sb 沒有找到 payQRcode 這個 component，待確認
          dispatch('actionOpenDialog', {
            componentName: 'payQRcode',
            closebtnColor: 'light',
            subData: {
              qrcode: data.qrcode,
              paymentMethodName,
            },
          }, { root: true });
        } else if (urlProperty) {
          windowPop.location.href = data.url;
          // if (rootState.isNativeApp) {
          // //* 先針對 FeiBaoPay 使用 url_format=hex, 確認 APP 都是最新版本後就可全部改用相同邏輯
          // //* 參考皇家及公版的寫法 OCMSV2-17653 再做些微調
          // //* 外層paymentMethodName不一定會有FeiBaoPay的字串 所以改採用data.thirdParty的方式
          //   if (data.url.match(/%([0-9A-F]{2})/) && ['FeiBaoPay'].includes(data.thirdParty)) {
          //   // 如果原本的 url 包含 %([0-9A-F]{2}) 無法正常使用 b64EncodeUnicode 處理(必須 iOS、Android 有支援的版本才看使用)
          //     window.location = `native://webview/openfullview?isblank=true&url_format=hex&url=${common.str2hex(data.url)}`;
          //   } else {
          //     window.location = `native://webview/openfullview?isblank=true&url_format=base64&url=${common.b64EncodeUnicode(data.url)}`;
          //   }
          // } else {
          //   windowPop.location.href = data.url;
          // }
        } else if (htmlProperty) {
          windowPop.document.write(data.html);
          // if (rootState.isNativeApp) {
          //   window.location = `native://webview/v2/openfullview?htmlcode=${common.b64EncodeUnicode(data.html)}&back_btn=true`;
          // } else {
          //   windowPop.document.write(data.html);
          // }
        } else if (requireFieldsProperty) {
          if (!rootState.isNativeApp) windowPop.close();
          // fieldsShow ：顯示不能編輯  （型態＿物件）
          // fieldsRequire： 顯示可編輯 （型態＿可能為陣列或物件)
          const {
            requireFields: {
              fieldsShow, fieldsRequire, fieldsShowV2, fieldsQRCode, fieldsQRCodeBase64,
            },
          } = data;
          let isFieldsShowExist = Object.prototype.hasOwnProperty.call(data.requireFields, 'fieldsShow');
          let isFieldsRequireExist = Object.prototype.hasOwnProperty.call(data.requireFields, 'fieldsRequire');
          let isFieldsQRCodeBase64Exist = Object.prototype.hasOwnProperty.call(data.requireFields, 'fieldsQRCodeBase64');
          let fieldsShowV2Length = Object.keys(fieldsShowV2).length;
          let fieldsShowValue = Object.keys(fieldsShow).length;
          let fieldsRequireValue;
          // fieldsRequire 有可能為空
          // TC-4511 第三方金流回傳的QRCode為base64的處理
          if (isFieldsQRCodeBase64Exist && fieldsShowV2Length > 0 && data.requireFields.fieldsShowV2[0].qrcode) {
            let base64URLValue = data.requireFields.fieldsShowV2[0].qrcode;
            return {
              componentName: 'qrCodeBase64Pay',
              postData: {
                base64URL: base64URLValue,
              },
            };
          }
          if (isArray(fieldsRequire)) {
            fieldsRequireValue = fieldsRequire.length;
          } else if (isObject(fieldsRequire)) {
            fieldsRequireValue = Object.keys(fieldsRequire).length;
          }
          if (fieldsShowValue && isFieldsShowExist && fieldsRequireValue && isFieldsRequireExist) {
            return {
              amount: postData.amount,
              componentName: 'onlinePaymentConfirm',
              fieldsData: data,
              postData: payload,
              title: paymentMethodName,
            };
          }
          if (fieldsShowValue && isFieldsShowExist) {
            return {
              componentName: 'onlinePayment',
              fieldsData: data,
              title: paymentMethodName,
            };
          }
        }
      })
      .catch((error) => {
        console.log(error);
        windowPop?.close();
      });
  },
  actionGetBankListByPaymentMethodType({ commit }, payload) {
    commit('setPaymentBankList', []);
    return getPaymentBankList(payload)
      .then(({ data }) => {
        commit('setPaymentBankList', data);
      });
  },
  actionQueryReward({ state, commit }, options = {}) {
    let { init, type } = options;
    if (init) {
      commit('setFinancialState', { rewardList: [], searchFilter: initSearchFilter() });
    }
    commit('setFinancialState', { queryLoading: true });
    return getRewardList(
      Object.assign(
        state.searchFilter,
        {
          sorts: [{
            columnName: 'order',
            orderType: 'asc',
          }],
        },
        {
          type,
        },
      ),
    )
      .then(({ data }) => {
        commit('setFinancialState', {
          rewardList: state.rewardList.concat(data.records), queryLoading: false, moreReward: data.count > data.records.length, rewardListTotalPage: Math.ceil(data.count / state.searchFilter.limit.pageSize),
        });
        return data;
      });
  },
  actionQueryRewardMore({ state, dispatch, commit }, number) {
    if (!state.moreReward) return;

    if (state.queryLoading) return;
    const { startIndex, pageSize } = state.searchFilter.limit;
    commit('setRewardSearchFilter', {
      limit: {
        startIndex: (number !== undefined) ? startIndex + number : startIndex + pageSize,
        pageSize: (number !== undefined) ? number : pageSize,
      },
    });

    dispatch('actionQueryReward').then(({ records, count }) => {
      commit('setFinancialState', { moreReward: count > state.rewardList.length });
    });
  },
  actionApplyReward(ctx, postData) {
    return sendApplyRedeem(postData);
  },
  actionQueryRedeemList({ state, commit }, payload) {
    commit('SET_REDEEM_LIST', { isLoading: true });
    return getRedeemList({ ...state.searchFilter, ...payload })
      .then(({ data }) => {
        commit('SET_REDEEM_LIST', { data, isLoading: false });
        return data;
      });
  },
  actionReset({ commit, state }) {
    commit('setFinancialState', { rewardList: [], redeemList: { isLoading: false, data: [] }, searchFilter: initSearchFilter() });
  },
  actionQueryPointRedeemControl({ commit }) {
    return getPointRedeemControl()
      .then(({ data: { data } }) => {
        commit('setFinancialState', { pointRedeemControl: data });
      });
  },
  async getAllRecord({ commit, state }, payload) {
    const query = {
      ...initRecordQueryData(RECORD_TYPE_DEPOSIT),
      ...payload,
      tradeType: undefined,
    };

    return new Promise(async (resolve, reject) => {
      if (state.depositRecord.isLoading) {
        console.warn('DepositRecord 讀取中');
        return reject(new Error('DepositRecord error'));
      }
      commit('SET_DEPOSIT_RECORD', { isLoading: true });
      const res = await getFinancialAllRecord(query).catch((e) => {
        console.log(e);
      });

      commit('SET_DEPOSIT_RECORD', { isLoading: false });
      if (res?.isSuccess) {
        commit('SET_DEPOSIT_RECORD', { data: res.data, isLoading: false });
        return resolve(res);
      }
      commit('SET_DEPOSIT_RECORD', { isLoading: false });
      return reject(new Error('DepositRecord error'));
    });
  },
  async getDepositRecord({ commit, state }, payload) {
    const query = {
      ...initRecordQueryData(RECORD_TYPE_DEPOSIT),
      ...payload,
    };

    return new Promise(async (resolve, reject) => {
      if (state.depositRecord.isLoading) {
        console.warn('DepositRecord 讀取中');
        return reject(new Error('DepositRecord error'));
      }
      commit('SET_DEPOSIT_RECORD', { isLoading: true });
      const res = await getFinancialRecord(query).catch((e) => {
        console.log(e);
      });

      commit('SET_DEPOSIT_RECORD', { isLoading: false });
      if (res?.isSuccess) {
        commit('SET_DEPOSIT_RECORD', { data: res.data, isLoading: false });
        return resolve(res);
      }
      commit('SET_DEPOSIT_RECORD', { isLoading: false });
      return reject(new Error('DepositRecord error'));
    });
  },
  async getRecoupmentRecord({ commit, state }) {
    const query = {
      ...initRecordQueryData(RECORD_TYPE_DEPOSIT),
      ...payload,
    };

    return new Promise(async (resolve, reject) => {
      if (state.recoupmentRecord.isLoading) {
        console.warn('RecoupmentRecord 讀取中');
        return reject(new Error('RecoupmentRecord error'));
      }
      commit('SET_RECOUPMENT_RECORD', { isLoading: true });
      const res = await getRecoupmentRecord(query).catch((e) => {
        console.log(e);
      });
      if (res?.isSuccess) {
        commit('SET_RECOUPMENT_RECORD', { data: res.data, isLoading: false });
        return resolve(res);
      }
      commit('SET_RECOUPMENT_RECORD', { isLoading: false });
      return reject(new Error('RecoupmentRecord error'));
    });
  },
  async getWithdrawRecord({ commit, state }, payload) {
    const query = {
      ...initRecordQueryData(RECORD_TYPE_WITHDRAW),
      ...payload,
    };

    return new Promise(async (resolve, reject) => {
      if (state.withdrawRecord.isLoading) {
        console.warn('WithdrawRecord 讀取中');
        return reject(new Error('WithdrawRecord error'));
      }
      commit('SET_WITHDRAW_RECORD', { isLoading: true });
      const res = await getFinancialRecord(query).catch((e) => {
        console.log(e);
      });
      if (res?.isSuccess) {
        commit('SET_WITHDRAW_RECORD', { data: res.data, isLoading: false });
        return resolve(res);
      }
      commit('SET_WITHDRAW_RECORD', { isLoading: false });
      return reject(new Error('WithdrawRecord error'));
    });
  },
  actionOnlinePaymentConfirm(_, payload) {
    return onlinePaymentConfirm(payload);
  },
  resetRecordLoadingStatus({ commit }) {
    commit('SET_DEPOSIT_RECORD', { isLoading: false });
    commit('SET_RECOUPMENT_RECORD', { isLoading: false });
    commit('SET_WITHDRAW_RECORD', { isLoading: false });
  },

  //
  // 取得所有平台現有金額
  actionQueryAllPlatMoney({ commit }) {
    return getAllPlatMoney().then(({ data }) => {
      const {
        totalCash,
        rewardPoint,
      } = data;
      commit('setWalletData', {
        totalAmount: totalCash,
        rewardPoint,
      });
    });
  },
  // 轉帳所有金額至某平台
  actionTransferAllPlatforms({ dispatch }) {
    return transAllPlatformMoney().then(({ data }) => {
      dispatch('actionQueryPromotionWallet', data);
    });
  },
  // 轉帳功能
  actionTransferSingle({ dispatch }, postData) {
    return transPlatformMoney(postData).then(({ data }) => {
      dispatch('actionQueryPromotionWallet', data);
    });
  },
  // 取得活動錢包功能
  actionQueryPromotionWallet({ rootGetters, commit }, { cashAmount, total, plat }) {
    return getPromotionWallet({
      sorts: [{ columnName: 'PromotionJoinId', orderType: 'desc' }],
      limit: { startIndex: 0, pageSize: 20 },
    }).then(
      ({
        data: { records },
      }) => {
        // 主錢包資訊
        let cashWallet = {};
        cashWallet.total = total;
        cashWallet.balance = cashAmount.balance;
        cashWallet.platform = sortPlatformFn(plat, 'cashWallet');

        // 活動錢包資訊
        let promoteWallet = {
          balance: 0,
          platform: [],
          total: 0,
        };

        // 塞入第一筆 main account
        cashWallet.platform.unshift({
          brand: 'all',
          balance: cashAmount.balance,
          isVisible: commonConstants.visible.VISIBLE,
          status: commonConstants.status.ENABLED,
        });

        if (records.length > 0) {
          let promotionRecords = records[0];
          promoteWallet.balance = parseFloat(promotionRecords.cashAmount);
          promoteWallet.total = parseFloat(promotionRecords.total);
          // 活動錢包遊戲平台資訊組合
          let platformCodeArray = Object.entries(
            promotionRecords.platformCodes,
          );
          platformCodeArray.forEach((item) => {
            let brand = item[0];
            promoteWallet.platform.push({
              brand,
              ...item[1],
            });
          });

          // 排序活動錢包遊戲平台(遊戲維護 or 網站維護放最後)
          promoteWallet.platform = sortPlatformFn(
            promoteWallet.platform,
            'promotionWallet',
          );
          // 塞入第一筆 promote account
          promoteWallet.platform.unshift({
            brand: 'all',
            cashAmount: promoteWallet.balance,
            isVisible: commonConstants.visible.VISIBLE,
            status: commonConstants.status.ENABLED,
          });
        }

        let fixedNum = rootGetters['initData/getInitDataByPath'](
          'systemConfig.system.config.currency.clientDecimalPlaces',
        );
        let totalAmount = new Big(total)
          .plus(promoteWallet.total)
          .toFixed(fixedNum);

        commit('setWalletData', {
          cashWallet,
          promoteWallet,
          totalAmount,
          rewardPoint: cashAmount.rewardPoint,
        });
      },
    );
  },
  actionDetectDepositWithdrawOrder({ rootState, dispatch }) {
    if (!rootState.member.token || rootState.isSubModalOpen) return;
    getMailList({
      mailType: mailConstants.type.FINANCIAL,
      sorts: [
        {
          columnName: 'addTime',
          orderType: 'desc',
        },
      ],
      limit: {
        startIndex: 0,
        pageSize: 999,
      },
    })
      .then(({ data }) => {
        if (data.unReadCount > 0) {
          let unReadMail = data.records.filter((mail) => mail.isRead === commonConstants.status.DISABLED);
          unReadMail.forEach((item) => {
            Message(item.content);
            updateMail({
              webmailId: item.id,
              isRead: commonConstants.status.ENABLED,
              isDelete: commonConstants.status.DISABLED,
            });
            if (item.messageType === mailConstants.type.FINANCIAL) {
              dispatch('actionQueryAllPlatMoney');
            }
          });
        }
      });
  },
  actionResetWallet: ({ commit }) => commit('RESET_WALLET'),
  actionGooglePlayBilling: (_, payload) => googlePlayBilling(payload),
  actionApplePayBilling: (_, payload) => applePayBilling(payload),
  actionSetDepositNotify: ({ commit }, payload) => commit('SET_DEPOSIT_NOTIFY', payload),
};

const mutations = {
  setIsOnlinePaymentTriggered(state, payload) {
    state.isOnlinePaymentTriggered = payload;
  },
  setPaymentCategory(state, payload) {
    state.paymentCategory = payload;
  },
  setPaymentContentList(state, payload) {
    state.paymentContentList = payload;
  },
  setPaymentMerchantList(state, payload) {
    state.paymentMerchantList = payload;
  },
  setPaymentBankList(state, payload) {
    state.paymentBankList = payload;
  },
  setManualDepositReceiptAccount(state, payload) {
    state.manualDepositReceiptAccount = payload;
  },
  setAutoCashInReceiptAccount(state, payload) {
    state.autoCashInReceiptAccount = payload;
  },
  setWithdrawSetting(state, payload) {
    state.withdrawSetting = payload;
  },
  setFinancialState(state, newState) {
    state = Object.assign(state, newState);
  },
  setRewardSearchFilter(state, payload) {
    Object.assign(state.searchFilter, {
      limit: {
        startIndex: 0,
        pageSize: 6,
      },
    }, payload);
  },
  SET_DEPOSIT_RECORD: (state, { data, isLoading }) => {
    data && (state.depositRecord.data = data);
    state.depositRecord.isLoading = isLoading;
  },
  SET_WITHDRAW_RECORD: (state, { data, isLoading }) => {
    data && (state.withdrawRecord.data = data);
    state.withdrawRecord.isLoading = isLoading;
  },
  SET_RECOUPMENT_RECORD: (state, { data, isLoading }) => {
    data && (state.recoupmentRecord.data = data);
    state.recoupmentRecord.isLoading = isLoading;
  },
  SET_REDEEM_LIST: (state, { data, isLoading }) => {
    data && (state.redeemList.data = data);
    state.redeemList.isLoading = isLoading;
  },
  resetPaymentMerchantIndex(state) {
    state.paymentMerchantIndex = 0;
  },
  setWalletData: (state, payload) => {
    state.wallet.cashWallet = payload.cashWallet;
    state.wallet.promoteWallet = payload.promoteWallet;
    state.wallet.totalAmount = payload.totalAmount;
    state.wallet.rewardPoint = payload.rewardPoint;
  },
  RESET_WALLET: (state) => {
    state.wallet = INIT_WALLET_DATA();
  },
  SET_DEPOSIT_NOTIFY: (state, payload) => {
    state.depositNotify = payload;
  },
};

const getters = {
  DepositRecord: (state) => state.depositRecord.data,
  WithdrawRecord: (state) => state.withdrawRecord.data,
  RecoupmentRecord: (state) => state.recoupmentRecord.data,
  RedeemList: (state) => state.redeemList.data,
  isCustomMerchantPriority: (state) => state.paymentContentList && state.paymentContentList.priorityType === constant.financial.deposit.merchant.priorityType.CUSTOM,
  getCurrentMerchantData: (state) => () => {
    const current = state.paymentMerchantList[state.paymentMerchantIndex++];
    const result = {
      thirdParty: current.payProvider,
      merchant: current.merchant,
    };
    if (state.paymentMerchantIndex === state.paymentMerchantList.length) {
      state.paymentMerchantIndex = 0;
    }
    return result;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
