import { map } from 'lodash';
import CONSTANT_FRIEND from '~/utils/constant/friend';
import {
  acceptFriendRequest,
  applyFriend,
  rejectFriendRequest,
  getFriendRequestList,
  searchMembers,
  getFriendList,
  removeFriend,
  getFriendInfo,
} from '~/api-tc/friend';
import { getSuggestFriend } from '~/api-tc/member';

const state = () => ({
  friendRequestCount: 0,
});

const mutations = {
  SET_FRIEND_REQUEST_COUNT: (state, count) => {
    state.friendRequestCount = count;
  },
};

const actions = {
  actionQueryFriendList: async ({ commit }, payload) => {
    const { data } = await searchMembers({
      sorts: [
        {
          columnName: 'memberId',
          orderType: 'asc',
        },
      ],
      ...payload,
    }).catch(() => []);
    return data;
  },
  actionGetFriendList: async ({ commit }, payload) => {
    const { data } = await getFriendList({
      status: CONSTANT_FRIEND.relationship.DEFAULT,
      ...payload,
    });
    return map(data, (value) => ({
      betLevel: value.friendBetLevel,
      code: value.friendCode,
      headImage: value.friendHeadImage,
      isOnline: value.isOnline,
      memberId: value.friendMemberId,
      nickname: value.friendNickName,
      username: value.friendUsername,
      lastLoginTime: value.friendLastLoginTime,
    }));
  },
  actionGetFriendRequestList: async ({ commit }, payload) => {
    const { data } = await getFriendRequestList({
      sorts: [
        {
          columnName: 'memberId',
          orderType: 'asc',
        },
      ],
      ...payload,
    });
    const records = map(data.records, (value) => ({
      betLevel: value.requesterBetLevel,
      code: value.requesterCode,
      headImage: value.requesterHeadImage,
      memberId: value.requesterId,
      nickname: value.requesterNickName,
      username: value.requesterUsername,
      lastLoginTime: value.requesterLoginTime,
    }));
    commit('SET_FRIEND_REQUEST_COUNT', data.count);
    return {
      records,
      count: data.count,
    };
  },
  actionUpdateFriendRequestCount: ({ commit }, count) =>
    commit('SET_FRIEND_REQUEST_COUNT', count),
  actionApplyFriend: async (_, payload) => {
    const { data } = await applyFriend(payload);
    return data;
  },
  actionAcceptFriendRequest: async (_, payload) => {
    const { data } = await acceptFriendRequest(payload);
    return data;
  },
  actionRejectFriendRequest: async (_, payload) => {
    const { data } = await rejectFriendRequest(payload);
    return data;
  },
  actionDeleteFriend: async (_, payload) => removeFriend(payload),
  actionGetFriendInfo: async (_, payload) => getFriendInfo(payload),
  actionGetSuggestFriend: async () => {
    const { data } = await getSuggestFriend();
    return data;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
