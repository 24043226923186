import at from 'lodash/at';
import i18n from '@/language';
import { reduce, sortBy } from 'lodash';
import TCAPI from '~/api-tc';
// import Big from 'big.js/big.mjs'
import fieldConfigMapping from '~/service/fieldConfigMapping';
import { extendMessages, extractPatterns } from '~/utils/veeValidate/customRules/utils';
// import { getArticleList, getArticleContent } from '@/api/article'
import commonConstants from '~/utils/constant/common';
import memberConstants from '~/utils/constant/member';
// import gameClassConstants from '@/utils/constant/game/gameClass'

function jackpotCheck(jackpot) {
  const detailProperty = Object.hasOwnProperty.call(jackpot, 'detail');
  if (!detailProperty) return false;
  if (!Array.isArray(jackpot.detail) || jackpot.detail.length === 0) return false;
  if (jackpot.jackpot === 0) return false;
  return true;
}

const jackpotCheckTime = 180000; // 3分鐘更新一次Jackpot
const secondPer = 2000; // 幾秒跳動一次
const valueChangeAverageTimes = jackpotCheckTime / secondPer; // n 分鐘 / 幾秒 => 到目標總共要跳幾次
// 此三個數字可考慮寫成設定檔

const state = () => ({
  agentId: null,
  loaded: false,
  systemConfig: {},
  currencyList: [],
  languageList: [],
  isMultiCurrency: false,
  platformList: [],
  jackpotList: [],
  jackpotListSource: [],
  gameTagList: [],
  gameClassList: [],
  webMainBannerList: [],
  appMainBannerList: [],
  promotionOne: [],
  promotionTwo: [],
  netWinList: [],
  bankList: [],
  popups: [],
  // promoteGames1List: [],
  // promoteGames2List: [],
  // hotGameList: [],
  webLogoPicture: [],
  appLogoPicture: [],
  ruleContent: [],
  footerContent: [],
  financialTutorialContent: [],
  communityList: [],
  articleList: {
    count: 0,
    records: [],
  },
  articlePageInfo: {
    id: 0,
    title: '',
    content: '',
  },
  rankControl: {
    moneyRank: {
      visible: false,
    },
    creditRank: {
      visible: false,
    },
  },
  gameTypeList: [
    {
      name: 'common.gameSearch.allGame',
      mobName: 'common.gameSearch.mob.allGame',
      imgUrl: '/images/game/gamesearch-icon1.png',
      value: 'all',
    },
    {
      name: 'common.gameSearch.hotGame',
      mobName: 'common.gameSearch.mob.hotGame',
      imgUrl: '/images/game/gamesearch-icon2.png',
      value: 'hot',
    },
    {
      name: 'common.gameSearch.newest',
      mobName: 'common.gameSearch.mob.newest',
      imgUrl: '/images/game/gamesearch-icon3.png',
      value: 'new',
    },
    {
      name: 'common.gameSearch.myGame',
      mobName: 'common.gameSearch.mob.myGame',
      imgUrl: '/images/game/gamesearch-icon4.png',
      value: 'favorite',
    },
    {
      name: 'common.gameSearch.recent',
      mobName: 'common.gameSearch.mob.recent',
      imgUrl: '/images/game/gamesearch-icon5.png',
      value: 'recently',
    },
  ],
  originConfig: {},
  mappingConfig: {},
  rewardControl: {},
  referralsPromotion: {},
  validateMessageList: {
    birthday: {
      required: 'common.required.birthday',
    },
    captcha: {
      required: 'common.required.captcha',
      isExist: 'common.errored.captcha',
    },
    email: {
      required: 'common.required.email',
      email: 'common.errored.email',
      regex: 'common.errored.email',
    },
    idCard: {
      required: 'member.personal.required.eamil',
      cnIdValidate: 'member.personal.errored.idcard',
      isExist: 'member.personal.idcardExist',
    },
    identityPicture: {
      required: 'member.personal.required.idPicture',
      pictureSize: 'common.system.error.ImgTip',
    },
    identityStatus: {
      required: 'member.personal.required.idStatus',
      cnIdValidate: 'member.personal.errored.idStatus',
    },
    pwd: {
      required: 'common.required.password',
      regex: 'common.errored.password',
    },
    pwdConfirm: {
      required: 'auth_signup.auth_signup.passwordConfirm',
      pwd: 'updatePassword.errored.confirmPassword',
      confirmed: 'updatePassword.errored.confirmPasswordNotSame',
    },
    phoneNumber: {
      required: 'common.required.phonenumber',
      regex: 'common.errored.phonenumber',
      isExist: 'common.errored.phonenumberExist',
    },
    realName: {
      required: 'common.required.realname',
      regex: 'common.errored.realname',
    },
    firstName: {
      required: 'member.personal.required.firstname',
      regex: 'member.personal.errored.firstname',
    },
    lastName: {
      required: 'member.personal.required.lastname',
      regex: 'member.personal.errored.lastname',
    },
    nickname: {
      required: 'common.required.required',
      nickname: 'member.error.nickname.emoji',
    },
    middleName: {
      regex: 'member.personal.errored.middleName',
    },
    username: {
      required: 'common.required.username',
      regex: 'common.errored.username',
      isExist: 'register.username.exists',
    },
    agree: {
      required: 'register.ruleCheck',
    },
    agree_modify: {
      required: 'register.ruleCheck',
    },
    agree_bank: {
      required: 'register.ruleCheck',
    },
    agree_contract: {
      required: 'register.ruleCheck',
    },
    gender: {
      // FIX ME
    },
    address: {
      required: 'common.required.required',
    },
    zipCode: {
      required: 'common.required.required',
      regex: 'member.personal.fillCorrectPostalCode',
    },
    qq: {
      required: 'common.required.required',
      regex: 'member.personal.fillTureQQ',
    },
    wechat: {
      required: 'common.required.required',
      regex: 'member.personal.fillTureWechat',
    },
    facebook: {
      required: 'common.required.required',
      regex: 'member.personal.fillTureFacebook',
    },
    line: {
      required: 'common.required.required',
      regex: 'member.personal.fillTureLine',
    },
    oldPwd: {
      required: 'updatePassword.required.originPassword',
      regex: 'updatePassword.errored.originPassword',
      pwd: 'updatePassword.errored.originPassword',
    },
    newPwd: {
      required: 'updatePassword.required.newPassword',
      regex: 'updatePassword.errored.newPassword',
      pwd: 'updatePassword.errored.newPassword',
      is_not: 'updatePassword.errored.compareOldPassword',
    },
    comfirmNewPwd: {
      required: 'updatePassword.required.confirmPassword',
      regex: 'updatePassword.errored.confirmPassword',
      pwd: 'updatePassword.errored.confirmPassword',
      is: 'updatePassword.errored.confirmPasswordNotSame',
    },
    bankName: {
      required: 'member.personal.fillBank',
      regex: 'common.errorText',
    },
    bankAccount: {
      required: 'member.bank.require.bankAccount',
      regex: 'member.bank.pattern.bankAccount',
    },
    bankBranch: {
      required: 'member.personal.fillCardholder',
    },
    province: {
      required: 'member.bank.require.province',
      regex: 'member.bank.pattern.province',
    },
    city: {
      required: 'member.bank.require.city',
      regex: 'member.bank.pattern.city',
    },
    ifsc: {
      required: 'member.personal.fillTureName',
      regex: 'member.personal.fillTureIFSC',
    },
    routingNumber: {
      required: 'member.personal.fillTureName',
      regex: 'member.personal.fillTureRoutingNumber',
    },
    bankAccountName: {
      required: 'member.personal.fillCardholder',
      regex: 'member.personal.fillTureCardholderName',
    },
    bankPicture: {
      required: 'member.personal.uploadImage',
      pictureSize: 'common.system.error.ImgTip',
    },
    eWallet_bankName: {
      required: 'member.personal.fillBank',
      regex: 'common.errorText',
    },
    eWallet_bankAccount: {
      required: 'member.personal.fillWalletBank',
      regex: 'member.personal.fillTrueWalletAccount',
      account: 'member.personal.fillTrueWalletAccount',
    },
    eWallet_bankAccountName: {
      required: 'member.personal.fillWalletAccountName',
      regex: 'member.personal.fillTrueWalletAccountName',
      bankAccountName: 'member.personal.fillTrueWalletAccountName',
    },
    eWallet_bankPicture: {
      required: 'member.personal.uploadImage',
      pictureSize: 'common.system.error.ImgTip',
    },
    crypto_bankName: {
      required: 'member.personal.fillBank',
      regex: 'common.errorText',
    },
    crypto_bankAccount: {
      required: 'member.personal.fillBank',
      regex: 'member.personal.fillTrueCryptoAddress',
    },
    crypto_bankBranch: {
      required: 'member.personal.fillBank',
    },
    crypto_bankAccountName: {
      required: 'member.personal.fillBank',
      regex: 'member.personal.fillTrueWalletAccountName',
    },
    identityNumber: {
      required: 'register.idcard.require',
      regex: 'personal.errored.correctIdnumber',
    },
    parentUsername: {
      // regex: 'register.referee.regex',
    },
    withdrawMemo: {
      required: 'common.required.required',
    },
    depositMemo: {
      required: 'common.required.required',
    },
    depositTime: {
      required: 'common.required.required',
    },
    receiptPicture: {
      required: 'member.personal.uploadImage',
      pictureSize: 'common.system.error.ImgTip',
    },
    country: {
      required: 'common.required.required',
      regex: 'member.personal.fillAddress',
    },
    primaryAddress: {
      required: 'common.required.required',
      regex: 'member.personal.fillAddress',
    },
    secondaryAddress: {
      required: 'common.required.required',
      regex: 'member.personal.fillAddress',
    },
    recipient: {
      required: 'common.required.required',
      regex: 'member.personal.fillRecipient',
    },
    trxId: {
      required: 'common.required.required',
      regex: 'member.deposit.fillTrueTrxId',
    },
    applyCount: {
      required: 'common.required.required',
      regex: 'member.rewardPointRedeem.reward.count.integer',
      checkRemainCount: 'financial.rewardPointRedeem.error.rewardNotEnough',
    },
    idCardType: {
      required: 'gcms.error.profilePictureType.require',
    },
    memberImage: {
      required: 'gcms.error.profilePicture.require',
    },
    nationality: {
      required: 'gcms.error.nationality.require',
    },
    placeOfBirth: {
      required: 'gcms.error.placeOfBirth.require',
    },
    presentAddress: {
      required: 'gcms.error.currentAddress.require',
    },
    permanentAddress: {
      required: 'gcms.error.permanentAddress.require',
    },
    natureOfWork: {
      required: 'gcms.error.occupation.require',
    },
    sourceOfFunds: {
      required: 'gcms.error.salarySource.require',
    },
    pinCode: {
      required: 'gcms.error.pinCode.require',
    },
    oldPinCode: {
      required: 'gcms.error.pinCode.require',
    },
    newPinCode: {
      required: 'gcms.error.newPinCode.require',
      is_not: 'updatePassword.errored.compareOldPassword',
    },
    confirmPinCode: {
      required: 'gcms.error.confirmNewPinCode.require',
      is: 'gcms.error.confirmNewPinCode.confirm',
    },
    telephone: {
      required: 'gcms.error.telephone.require',
    },
    otp: {
      required: 'common.required.otp',
    },
  },
  fieldNameMapping: {
    username: 'common.username',
    pwd: 'common.password',
    pwdConfirm: 'Auth_Signup.passwordConfirm',
    realName: 'common.realname',
    firstName: 'member.personal.firstname',
    lastName: 'member.personal.lastname',
    phoneNumber: 'common.phonenumber',
    captcha: 'common.captcha',
    facebook: 'member.personal.facebook',
    line: 'member.personal.line',
    wechat: 'member.personal.wechat',
    qq: 'member.personal.QQaccount',
    email: 'member.personal.mail',
    zipCode: 'member.personal.postalcode',
    address: 'member.personal.address',
    birthday: 'common.birthday',
    gender: 'member.personal.gender',
    identityNumber: 'member.personal.idnumber',
    identityPicture: 'member.personal.idPicture',
    identityStatus: 'member.personal.idStatus',
    parentUsername: 'common.parentMember',
    oldPwd: 'updatePassword.originPassword',
    newPwd: 'updatePassword.newPassword',
    comfirmNewPwd: 'updatePassword.confirmPassword',
  },
  appDownloadUrl: `${window.location.origin}/appdownload.html`,
});

const getters = {
  getInitDataByPath: (state) => (path) => {
    const value = at(state, path)[0];

    return value;
  },
  getSystemConfig: (state) => (path) => {
    const value = at(state.systemConfig, path);
    switch (value.length) {
      case 0:
        return null;
      case 1:
        return value[0];
      default:
        return value;
    }
  },
  getLangList: (state) => state.languageList,
  getIsMultiCurrency: (state) => state.isMultiCurrency,
  getFieldConfigByPath: (state) => (path) => {
    const value = at(state.mappingConfig, path)[0] || {};

    return value;
  },
  getArticleList: (state) => state.articleList,
  getGameClassByType: (state) => (type) => ((type === undefined) ? state.gameClassList : state.gameClassList.filter((item) => item.type === type && item.status === 1)),
  getJackpotByPlatform: (state) => (platName) => ((platName === undefined) ? state.jackpotList : state.jackpotList.filter((item) => item.platName === platName)),
  getPlatform: (state) => (brand) => ((brand === undefined) ? state.platformList : state.platformList.filter((item) => item.brand === brand)),
  getFinancialTutorial: (state) => (name) => ((name === undefined) ? state.financialTutorialContent : state.financialTutorialContent.filter((i) => i.link === name)),
  // getGameClassStatus: state => (type, subType) => {
  //   // type, subType定義自 src/utils/constant/game/gameClass.js
  //   const result = state.gameClassList.filter(item => item.type === type && item.subType === subType && item.status === commonConstants.status.ENABLED)
  //   // eslint-disable-next-line no-unneeded-ternary
  //   return result.length > 0 ? true : false
  // },
  // isShowCockFight: state => () => {
  //   // TC-4049 判斷鬥雞是否開啟
  //   const result = state.gameClassList.filter(item => item.type === gameClassConstants.type.OTHER && item.subType === gameClassConstants.subType.COCK_FIGHTING && item.status === commonConstants.status.ENABLED)
  //   // eslint-disable-next-line no-unneeded-ternary
  //   return result.length > 0 ? true : false
  // },
  isShowRanking: (state, getters) => {
    // TC-3222 判斷龍虎榜是否顯示
    const { creditRank, moneyRank } = getters.getInitDataByPath('rankControl');
    return creditRank.visible && moneyRank.visible;
  },
  getAppDownloadUrl: (state) => state.appDownloadUrl,
  getCaptchaSettings: (state) => (path) => {
    const captchaSwitch = state.systemConfig.system.captcha[path];
    return !!captchaSwitch;
  },
  isRecaptchaEnabled: (state, getters) => !!getters.getSystemConfig('system.recaptcha.switch'),
  isGCMSSyncEnable: (state) => state.systemConfig.GCMS_SYNC_MEMBER,
  getCurrencySymbol: (state) => {
    const symbolKeyName = state.systemConfig.system.config.currency.symbol || 'THB';
    return `common.currencySymbol.${symbolKeyName}`;
  },
  getLoginSettings: (_, getters) => sortBy(
    reduce(
      memberConstants.loginSetting,
      (result, name, key) => {
        const setting = getters.getSystemConfig(name);
        if (setting?.status === commonConstants.status.ENABLED) {
          result.push({
            name,
            ...setting,
          });
        }
        return result;
      },
      [],
    ),
    ['order'],
  ),
  availableFooterContent: (state) => state.footerContent.filter((item) => item.status === commonConstants.status.ENABLED),
  deleteAccountUrl: (state) => state.deleteAccountUrl,
  isBonusNotifyEnable: (state) =>
    !!(state.bonusSetting?.win || state.bonusSetting?.rate),
};

const actions = {
  async getInitData({ commit, dispatch }) {
    dispatch('actionSetDeviceType', undefined, { root: true });
    const res = await TCAPI.base.getInitData({ deviceType: commonConstants.deviceType.MOBILE }).catch((err) => {
      console.log(err);
    });
    if (res?.isSuccess) {
      commit('setInitData', res.data);
      dispatch('actionSetFieldConfig', res.data?.systemConfig.fields);
    }
    dispatch('initLanguage');
    dispatch('extendVeeValidate');
    return res;
  },
  async initLanguage({ dispatch, rootState, state }) {
    const find = state.languageList.find((lang) => lang.lang === rootState.app.language);
    const defaultLang = state.languageList.find((data) => data.isDefault)?.lang || state.languageList[0]?.lang;
    const language = find?.lang || defaultLang || 'zh-Hant';
    i18n.locale = language;
    dispatch('app/setLanguage', language, { root: true });
  },
  extendVeeValidate({ dispatch, state }) {
    dispatch('extractPatterns');
    dispatch('extendValidateMessages');
  },
  extractPatterns({ state }) {
    extractPatterns(state.mappingConfig);
  },
  extendValidateMessages({ rootGetters }) {
    extendMessages(rootGetters.language);
  },
  actionQueryInitData({ state, commit }) {
    const obj = {};
    this.$http.post('/service/initData')
      .then(({ data: { data } }) => {
        commit('setInitData', { loaded: true, ...data });
        if (state.currencyList.length > 1) {
        // 多幣別語系
          data.languageList.forEach((item) => {
            if (!obj[item.currency] && item.currency !== undefined) {
              obj[item.currency] = [];
            }
            obj[item.currency].push(item);
          });
          commit('setInitData', { languageList: obj });
          commit('setInitData', { isMultiCurrency: true }); // service/currency/getList回傳的值長度 > 1 才視為多幣別
        }
      });
  },
  actionSetFieldConfig({ commit }, config) {
    commit('setSystemConfig', config);
  },
  // actionQueryJackpot({ commit, state, dispatch }) {
  //   const fixedNum = at(state, 'systemConfig.system.config.currency.clientDecimalPlaces')[0]
  //   const { jackpotList: jackpotListOldValue } = state

  //   this.$http.post('/service/game/jackpot/getList', {}, { headers: { AutoLoading: false }})
  //     .then(({ data: { data: jackpotListSource }}) => {
  //     // 1. 先判斷成功否
  //     // 2. 加工
  //     // 3. 更新
  //       const jackpotList = []
  //       jackpotListSource.forEach((item) => {
  //         if (jackpotCheck(item)) {
  //           const oldValueIndex = jackpotListOldValue.findIndex(olditem => olditem.platName === item.platName)
  //           const hasOldValue = oldValueIndex !== -1 // jackpot有舊值
  //           const jackpotItemNewValue = {};
  //           ['Grand', 'Major', 'Minor'].forEach((jackpotclass) => {
  //             const targetIndex = item.detail.findIndex(detailItem => detailItem.GameName === jackpotclass)
  //             const varname = jackpotclass.toLocaleLowerCase()
  //             // 第一次取無法比對 先將初次取出的數字扣一點慢慢加回原本的數字
  //             const randomNum = new Big((Math.floor(Math.random() * 300) / 9).toFixed(fixedNum)) // 最大 33.33
  //             let addValue = randomNum.div(valueChangeAverageTimes)
  //             let itemValue = Big(item.detail[targetIndex].val).minus(randomNum).minus(1)

  //             if (item.detail[targetIndex].min && itemValue.lte(item.detail[targetIndex].min)) {
  //             // 如果jackpot剛被拉走 起始值就是最小值
  //               itemValue = Big(item.detail[targetIndex].min)
  //               addValue = Big(item.detail[targetIndex].val).minus(itemValue).div(valueChangeAverageTimes)
  //             }

  //             if (hasOldValue) {
  //               itemValue = Big(jackpotListOldValue[oldValueIndex][varname]).minus(1)
  //               if (Big(item.detail[targetIndex].val).lt(itemValue)) {
  //               // 擁有舊有值 又Jackpot被拉走 (現值)
  //                 itemValue = (item.detail[targetIndex].min) ? Big(item.detail[targetIndex].min) : Big(item.detail[targetIndex].val).minus(randomNum)
  //               }
  //               addValue = Big(item.detail[targetIndex].val).minus(itemValue).div(valueChangeAverageTimes)
  //             }

  //             Object.assign(jackpotItemNewValue, item, {
  //               [varname]: Big(itemValue).toFixed(fixedNum),
  //               [`${varname}AddValue`]: addValue,
  //               [`${varname}Max`]: (item.detail[targetIndex].max == undefined) ? '' : item.detail[targetIndex].max,
  //               [`${varname}Min`]: (item.detail[targetIndex].min == undefined) ? '' : item.detail[targetIndex].min
  //             })
  //           })

  //           if (hasOldValue) {
  //             jackpotList[oldValueIndex] = jackpotItemNewValue
  //           } else {
  //             jackpotList.push(jackpotItemNewValue)
  //           }
  //         }
  //       })
  //       dispatch('jackpotAccumulator')
  //       commit('setInitData', { jackpotListSource, jackpotList })
  //     })
  // },
  jackpotAccumulator({ state, commit }) {
    if (!Object.hasOwnProperty.call(state, 'jackpotAccumulator')) {
      state.jackpotAccumulator = setInterval(() => {
        commit('jackpotAccumulator');
      }, secondPer);
    }
  },
  // actionQueryArticleList({ commit }, opts) {
  //   getArticleList(opts)
  //     .then(({ data: { data }}) => {
  //       const processList = data.records.map((article) => {
  //         const src = /<img[^>]+src="([^">]+)"[^>]+>/.exec(article.content)
  //         const alt = /<img[^>]+alt="([^">]+)"[^>]+>/.exec(article.content)
  //         return Object.assign(article, {
  //           imgUrl: (src !== null) ? src[1] : '',
  //           imgAlt: (alt !== null) ? alt[1] : ''
  //         })
  //       })
  //       commit('setInitData', {
  //         articleList: {
  //           count: data.count,
  //           records: processList
  //         }
  //       })
  //       setTimeout(() => window.scrollTo(0, 0), 0)
  //     })
  // },
  // actionQueryArticleInfo({ commit }, articlePageId) {
  //   if (articlePageId === undefined) return
  //   getArticleContent(parseInt(articlePageId, 10))
  //     .then(({ data: { data: articlePageInfo }}) => {
  //       commit('setInitData', { articlePageInfo })
  //     })
  // },
  actionSetArticleInfo({ commit }, articlePageInfo) {
    if (articlePageInfo === undefined) return;
    commit('setInitData', { articlePageInfo });
  },
};

const mutations = {
  setInitData(state, data) {
    Object.assign(state, data);
  },
  setSystemConfig(state, config) {
    state.originConfig = config;
    // member、manual使用mappingConfig redeem使用mappingRedeemConfig
    state.mappingConfig = fieldConfigMapping.mapping(config, ['member', 'manual']);
    state.mappingRedeemConfig = fieldConfigMapping.mapping(config, ['redeem']);
  },
  setAgentId(state, id) {
    state.agentId = id;
  },
  // jackpotAccumulator(state) {
  //   const fixedNum = at(state, 'systemConfig.system.config.currency.clientDecimalPlaces')[0]

  //   state.jackpotList.forEach((item) => {
  //     item.grand = Big(item.grand).plus(item.grandAddValue).toFixed(fixedNum)
  //     item.major = Big(item.major).plus(item.majorAddValue).toFixed(fixedNum)
  //     item.minor = Big(item.minor).plus(item.minorAddValue).toFixed(fixedNum)
  //   })
  // }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
