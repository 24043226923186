/* eslint-disable import/no-cycle */
import axios from 'axios';
import { detectDevice } from '~/utils/detectDevice';
import { getMaintainInfo } from '~/api-tc/base';
import {
  InAppBrowserDeviceType,
} from '~/utils/constant';
import { logout } from '~/api-tc/member';

const state = () => ({
  isNativeApp: false,
  isShowRotateModal: false,
  platform: null,
  activeCustomerService: null,
  deviceType: state.isNativeApp ? InAppBrowserDeviceType : detectDevice(),
  maintainInfo: {
    status: false,
    startTime: '',
    endTime: '',
    info: '',
  },
});

const mutations = {
  SET_PLATFORM: (state, payload) => {
    state.platform = payload;
    state.isNativeApp = !!payload;
    state.detectDevice = payload ? InAppBrowserDeviceType : detectDevice();
  },
  SET_ACTIVE_CUSTOMER_SERVICE: (state, payload) => {
    state.activeCustomerService = payload;
  },
  SET_DEVICE_TYPE: (state, payload) => {
    state.deviceType = payload;
  },
  SET_MAINTAIN_INFO: (state, payload) => {
    state.maintainInfo = payload;
  },
  SET_IS_SHOW_ROTATE_MODAL: (state, payload) => {
    state.isShowRotateModal = payload;
  },
};

const actions = {
  logoutRequest: async ({ getters }) => {
    const res = await logout({
      deviceType: getters.deviceType,
    }).catch((err) => err);
    return res;
  },
  logout: async ({ dispatch }, autoLogout = true) => {
    dispatch('member/logout', autoLogout);
    dispatch('timer/resetAll');
    dispatch('chat/actionClearAllHistory', true);
  },
  actionSetPlatform: ({ commit }, payload) => {
    commit('SET_PLATFORM', payload);
  },
  actionSetActiveCustomerService: ({ commit }, payload) => commit('SET_ACTIVE_CUSTOMER_SERVICE', payload),
  actionSetDeviceType({ commit, state }) {
    commit('SET_DEVICE_TYPE', state.isNativeApp ? InAppBrowserDeviceType : detectDevice());
  },
  actionGetMaintainInfo({ commit, state }) {
    return getMaintainInfo()
      .then((res) => {
        if (res.isSuccess) {
          commit('SET_MAINTAIN_INFO', {
            ...res.data,
            status: !!res.data?.status,
          });
        }
      })
      .catch((err) => {
        const settingFilePath = `${process.env.VUE_APP_MAINTAIN_PATH}?v=${new Date().getTime()}`;
        axios.get(settingFilePath)
          .then((res) => {
            if (res.data) {
              commit('SET_MAINTAIN_INFO', {
                ...state.maintainInfo,
                ...res.data,
                status: !!res.data?.status,
              });
            }
          });
      });
  },
  actionSetShowRotateModal({ commit }, payload) {
    commit('SET_IS_SHOW_ROTATE_MODAL', payload);
  },
};

const getters = {
  Token: (state) => state.user.tn,
  Device: (state) => state.user.device,
  AgentCode: (state) => state.user.ac,
  DeviceID: (state) => state.user.DeviceID,
  LocationID: (state) => state.app.LocationID,
  Version: (state) => state.app.version,
  language: (state) => state.app.language,
  isGameView: (state) => state.app.isGameView,
  isLoading: (state) => state.app.isLoading,
  MemberInfo: (state) => state.user.MemberInfoData,
  CurrencyID: (state) => state.user.MemberInfoData.CurrencyID,
  CurrencyRate: (state) => state.user.MemberInfoData.CurrencyRate,
  CurrencyName: (state) => state.user.MemberInfoData.CurrencyName,
  FBInfo: (state) => state.user.FBInfo,
  TimerCount: (state) => state.timer.count,
  TimerLock: (state) => state.timer.lock,
  TimerTempData: (state) => state.timer.tempData,
  TimerFirstClick: (state) => state.timer.firstClick,
  favGames: (state) => state.app.favGames,
  isAlertShow: (state) => state.app.isAlertShow,
  isMaintainence: (state) => state.app.isMaintainence,
  MaintainContent: (state) => state.app.MaintainContent,
  Domains: (state) => state.app.Domains,
  ProviderList: (state) => state.app.ProviderList,
  SocketVersion: (state) => state.app.SocketVersion,
  HasDailyReward: (state) => state.user.HasDailyReward,
  GameFilter: (state) => state.app.GameFilter,
  isMemberInfoLoading: (state) => state.user.isMemberInfoLoading.isGameView,
  isOpenGameWallet: (state) => state.app.isOpenGameWallet,
  HasLottery: (state) => state.user.HasLottery,
  HasDiamondShop: (state) => state.user.HasDiamondShop,
  DiamondAmount: (state) => state.user.DiamondAmount,
  LocationCode: (state) => state.app.LocationCode,
  CanPromotionFinish: (state) => state.user.CanPromotionFinish,
  IsAvoidFlow: (state) => false, // 給Google審查避開金流, 新版未實作,
  activeCustomerService: (state) => state.activeCustomerService,
  deviceType: (state) => (state.isNativeApp ? InAppBrowserDeviceType : detectDevice()),
};

export default {
  state,
  getters,
  mutations,
  actions,
};
