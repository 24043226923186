import {
  getMailList, updateMail, getBonusList, drawBonus, getNews,
  setAllMailStatus,
} from '~/api-tc/mail';

import promotionConstant from '~/utils/constant/promotion';
import mailConstant from '~/utils/constant/mail';

const state = () => ({
  action: {
    count: 0,
    records: [],
    unReadCount: 0,
  },
  system: {
    count: 0,
    records: [],
    unReadCount: 0,
  },
  personal: {
    count: 0,
    records: [],
    unReadCount: 0,
  },
  bonus: {
    count: 0,
    records: [],
    unReadCount: 0,
  },
  news: [],
  limit: {
    pageSize: 10,
    startIndex: 0,
  },
});

const getters = {
  getActionMailBox: (state) => state.action,
  getSystemMailBox: (state) => state.system,
  getPersonalMailBox: (state) => state.personal,
  getBonusMailBox: (state) => state.bonus,
  getNews: (state) => state.news,
  getFilterNews: (state) => (state.news.length > 5 ? state.news.slice(0, 5) : state.news),
  getAllMailBox: (state) => state,
  getActionUnRead: (state) => state.action.unReadCount,
  getSystemUnRead: (state) => state.system.unReadCount,
  getPersonalUnRead: (state) => state.personal.unReadCount,
  getBonusUnReceive: (state) => state.bonus.unReadCount,
  getAllUnRead: (state, getters) => state.action.unReadCount
		+ state.system.unReadCount
		+ state.personal.unReadCount
    + getters.getBonusUnReceive,
};

const actions = {
  /*
    {
      order: this.pagination.order,
      start: (this.pagination.currentPage - 1) * this.pagination.pageSize,
      pagesize: this.pagination.pageSize,
      searchMessageTypeId: this.getTypeConfig('id'),
      type: this.type
    }
  */
  actionGetMailBox({ commit, dispatch }, opts) {
    dispatch('actionSetLimit', opts.limit);
    getMailList(opts).then(({ data }) => {
      commit('setMailBox', { data, type: opts.type });
    });
  },
  actionGetNews: ({ commit }, opts) => getNews(opts).then(({ data }) => commit('setNews', data)),
  actionGetAllMailBox({ commit, dispatch, state }) {
    dispatch('actionSetLimit', {
      startIndex: 0,
      pageSize: 10,
    });
    const mailPayload = (mailType) => ({
      mailType,
      sorts: [
        {
          columnName: 'addTime',
          orderType: 'desc',
        },
      ],
      limit: state.limit,
    });

    getMailList(mailPayload(mailConstant.mailType.SYSTEM)).then(
      ({ data }) => {
        commit('setMailBox', { data, type: mailConstant.type.SYSTEM });
      },
    );

    getMailList(mailPayload(mailConstant.mailType.PROMOTION)).then(
      ({ data }) => {
        commit('setMailBox', { data, type: mailConstant.type.PROMOTION });
      },
    );

    getMailList(mailPayload(mailConstant.mailType.PERSONAL)).then(
      ({ data }) => {
        commit('setMailBox', { data, type: mailConstant.type.PERSONAL });
      },
    );
  },
  actionUpdateMail(payload, opts) {
    return updateMail(opts);
  },
  actionGetBonusList({ commit, dispatch }, opts) {
    dispatch('actionSetLimit', opts.limit);
    return getBonusList(opts).then(({ data }) => {
      commit('setMailBox', { data, type: 'bonus' });
    });
  },
  actionDrawBonus({ commit }, opts) {
    return drawBonus(opts);
  },
  actionSetLimit({ commit }, payload) {
    commit('setLimit', payload);
  },
  actionSetAllMailStatus(_, payload) {
    return setAllMailStatus(payload);
  },
};

const mutations = {
  setMailBox(state, opts) {
    state[opts.type] = opts.data;
  },
  setNews(state, opts) {
    state.news = opts.data;
  },
  setLimit(state, payload) {
    state.limit.pageSize = payload.pageSize;
    state.limit.startIndex = payload.startIndex;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
