import moment from 'moment';
import {
  getGiftList,
} from '~/api-tc/gift';

const state = {
  giftList: {
    gifting: {
      count: 0,
      unclaimed: 0,
      records: [],
    },
    receive: {
      ount: 0,
      unclaimed: 0,
      records: [],
    },
  },
  limit: {
    pageSize: 10,
    startIndex: 0,
  },
};

const getters = {
  getGiftList(state) {
    return state.giftList;
  },
  getUnClaimedCount(state) {
    if (state.giftList.gifting.unclaimed === undefined) return 0;
    return state.giftList.gifting.unclaimed + state.giftList.receive.unclaimed ?? 0;
  },
};

const mutations = {
  SET_GIFTLIST(state, data) {
    state.giftList = data;
  },
  SET_LIMIT(state, data) {
    state.limit.pageSize = data?.pageSize || 10,
    state.limit.startIndex = data?.startIndex || 0;
  },
};

const actions = {
  actionSetLimit({ commit }, payload) {
    commit('SET_LIMIT', payload);
  },
  actionGetGiftList({ commit, dispatch, state }, limit) {
    dispatch('actionSetLimit', limit);
    const createStartTime = moment().subtract(30, 'days').startOf('day').format('YYYY/MM/DD HH:mm:ss');
    const createEndTime = moment().endOf('day').format('YYYY/MM/DD HH:mm:ss');

    const formData = {
      createStartTime,
      createEndTime,
      limit: state.limit,
      sorts: [
        {
          columnName: 'CreateTime',
          orderType: 'desc',
        },
        {
          columnName: 'Status',
          orderType: 'asc',
        },
      ],
    };
    return getGiftList(formData)
      .then((res) => {
        commit('SET_GIFTLIST', res.data);
        return res;
      })
      .catch((err) => err);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
