/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { isBefore, isAfter, parseISO } from 'date-fns';
import i18n from '@/language';
import {
  getPromotionRecommend,
  getPromotionRecommendMembers,
  getPromotionInfo,
  getPromotionInfoByUser,
  getPromotionInfoTagList,
  getVoucherCodePromotionInfo,
  getPromotionHistory,
  getLuckyDrawVisible,
  getLotteryTicket,
} from '~/api-tc/promotion';
import { PromotionInfoTagTypeCustom, MobileDeviceType } from '~/utils/constant';
import CONSTANT_PROMOTION from '~/utils/constant/promotion';

const state = () => ({
  promotionRecommend: {
    records: [],
    count: 0,
  },
  promotionRecommendMember: {
    records: [],
    count: 0,
  },
  promotionHistory: {
    records: [],
    count: 0,
  },
  tags: [],
  availableTags: [],
  availableTagsSet: null,
  selectedTag: 0,
  promotionInfoList: [],
  promotionInfoSource: [],
  promotionInfoListVoucherSource: [],
  countDownTimes: 0,
  countDownTimer: null,
  luckyDrawPageStatus: false,
  lotteryTicket: 0,
});

const mutations = {
  setPromotionRecommend(state, data) {
    state.promotionRecommend = data;
  },
  SET_PROMOTIONS: (state, data) => {
    state.Promotions = data;
  },
  SET_LEVELINFO: (state, data) => {
    state.LevelInfo = data;
  },
  SET_PROMOTION_INFO_SOURCE: (state, data) => {
    state.promotionInfoSource = data;
  },
  SET_AVAILABLE_TAGS: (state) => {
    const originTagsSet = new Set(state.tags.map((tag) => tag.promotionInfoTagId));
    state.availableTagsSet = new Set();
    // state.availableTagsSet.add(CONSTANT_PROMOTION.promotionInfoTagIdType.ALL);
    state.promotionInfoSource.forEach((promotion) => promotion.promotionInfoTagList.forEach((tag) => {
      if (originTagsSet.has(tag)) {
        state.availableTagsSet.add(tag);
      }
    }));
    state.availableTagsSet.delete(CONSTANT_PROMOTION.promotionInfoTagCode.VOUCHER_CODE);
    state.availableTags = state.tags.filter((tag) => state.availableTagsSet.has(tag.promotionInfoTagId));
  },
  SET_SELECTED_TAG: (state, data) => {
    state.selectedTag = data;
  },
  SET_TAGS: (state, tags) => {
    // [all, ...customTags, history]
    state.tags = [
      {
        name: i18n.t('promoteMenu.all'),
        code: CONSTANT_PROMOTION.promotionInfoTagCode.ALL,
        promotionInfoTagId: CONSTANT_PROMOTION.promotionInfoTagIdType.ALL,
      },
      ...tags,
      // { name: i18n.t('promoteMenu.history'), code: CONSTANT_PROMOTION.promotionInfoTagCode.HISTORY, promotionInfoTagId: CONSTANT_PROMOTION.promotionInfoTagIdType.HISTORY },
    ];
  },
  SET_PROMOTION_INFO_LIST: (state, data) => {
    state.promotionInfoList = data;
  },
  SET_PROMOTION_INFO_LIST_VOUCHER_SOURCE: (state, data) => {
    state.promotionInfoListVoucherSource = data;
  },
  setPromotionRecommendMember(state, data) {
    state.promotionRecommendMember = data;
  },
  setPromotionHistory(state, data) {
    state.promotionHistory = data;
  },
  SET_COUNT_DOWN_TIMES: (state, times) => {
    state.countDownTimes = times;
  },
  SET_COUNT_DOWN_TIMER: (state, timer) => {
    state.countDownTimer = timer;
  },
  SET_LUCKY_DRAW_PAGE_STATUS(state, data) {
    state.luckyDrawPageStatus = data;
  },
  SET_LOTTERY_TICKET(state, data) {
    state.lotteryTicket = data;
  },
};

const actions = {
  actionPromotionRecommend({ commit }, opts) {
    return getPromotionRecommend(opts).then((data) => {
      commit('setPromotionRecommend', data.data);
    });
  },
  getPromotions: async ({ commit, state }) => new Promise(async (resolve, reject) => {
    if (state.Promotions.isLoading) {
      console.warn('Promotion讀取中');
      reject(new Error('error'));
    }
    commit('SET_PROMOTIONS', { ...state.Promotions, isLoading: true });
    const res = await API.Activity.Get_PromotionList().catch((err) => {
      console.log(err);
      commit('SET_PROMOTIONS', { ...state.Promotions, isLoading: false });
    });
    if (res?.ErrorCode === 0) {
      commit('SET_PROMOTIONS', { data: res.Data, isLoading: false });
      resolve(res);
    } else {
      commit('SET_PROMOTIONS', { ...state.Promotions, isLoading: false });
      reject(new Error('error'));
    }
  }),
  async getLevelInfo({ commit, state }) {
    if (state.LevelInfo.isLoading) {
      console.warn('Level Info 讀取中');
      return;
    }
    commit('SET_LEVELINFO', { ...state.LevelInfo, isLoading: true });
    const res = await API.Activity.GetUserLevelIntro().catch((e) => {
      console.log(e);
      commit('SET_LEVELINFO', { ...state.LevelInfo, isLoading: false });
    });
    commit('SET_LEVELINFO', false);
    if (res?.ErrorCode === 0) {
      commit('SET_LEVELINFO', { data: res.Data, isLoading: false });
    } else {
      commit('SET_LEVELINFO', { ...state.LevelInfo, isLoading: false });
    }
  },
  getAllData({ dispatch }) {
    dispatch('getPromotions');
    dispatch('getLevelInfo');
  },
  resetLoading({ commit, state }) {
    commit('SET_PROMOTIONS', { ...state.Promotions, isLoading: false });
    commit('SET_LEVELINFO', { ...state.LevelInfo, isLoading: false });
  },
  actionQueryPromotionInfo({ rootState, commit }) {
    // const getPromotionInfoReq = rootState.loginStatus
    //   ? getPromotionInfoByUser
    //   : getPromotionInfo;
    const getPromotionInfoReq = getPromotionInfoByUser;
    return getPromotionInfoReq({ device: MobileDeviceType }).then(
      ({ data }) => {
        const now = new Date();
        const source = data.filter((promotion) => isBefore(now, parseISO(promotion.eventShowEndTime)));
        commit('SET_PROMOTION_INFO_SOURCE', source);
      },
    );
  },
  actionQueryPromotionInfoTagList({ commit }) {
    return getPromotionInfoTagList().then(({ data }) => {
      let customTags = data.filter(
        (tagsItem) => tagsItem.type === PromotionInfoTagTypeCustom,
      );
      commit('SET_TAGS', customTags);
    });
  },
  setSelectedTag({ commit, state }, index) {
    commit('SET_SELECTED_TAG', state.availableTags?.[index] ?? {});
  },
  actionPromotionInfoTabClick({ commit, state }, index) {
    const selectedTag = state.availableTags[index] ?? state.availableTags[0];
    const tag = selectedTag.promotionInfoTagId;
    let sourceArr = state.promotionInfoListVoucherSource.concat(
      state.promotionInfoSource,
    );
    commit('SET_SELECTED_TAG', selectedTag);
    commit(
      'SET_PROMOTION_INFO_LIST',
      (() => {
        switch (tag) {
          case CONSTANT_PROMOTION.promotionInfoTagIdType.ALL:
            state.promotionAll = sourceArr
              // .filter((promotion) => promotion.promotionInfoTagList.some((tagId) => state.availableTagsSet.has(tagId)))
              .sort((a, b) => {
                if (a.buttonType === CONSTANT_PROMOTION.buttonType.RELEASE) return -1;
                if (b.buttonType === CONSTANT_PROMOTION.buttonType.RELEASE) return 1;
                return 0;
              });
            return state.promotionAll;
          case 'history':
            return sourceArr.filter((item) => isAfter(new Date(), parseISO(item.endTime)));
          default:
            return sourceArr.filter(
              (item) => item.promotionInfoTagList.findIndex((i) => i == tag) !== -1,
            );
        }
      })(),
    );
  },
  actionGetVoucherCodePromotionInfo({ rootState, commit }) {
    return getVoucherCodePromotionInfo({ device: rootState.deviceType }).then(
      ({ data: { data } }) => {
        commit('SET_PROMOTION_INFO_LIST_VOUCHER_SOURCE', data);
        return data;
      },
    );
  },
  getPromotionRecommendMember({ commit }, data) {
    return getPromotionRecommendMembers(data).then((data) => {
      commit('setPromotionRecommendMember', data.data);
    });
  },
  actionGetPromotionHistory({ commit }, opts) {
    getPromotionHistory({
      limit: {
        startIndex: 0,
        pageSize: 10,
      },
      sorts: [
        {
          columnName: 'SuccessTime',
          orderType: 'desc',
        },
      ],
      ...opts,
    }).then(({ data }) => commit('setPromotionHistory', data));
  },
  actionInitCountDownTimer({ commit, state }, times) {
    commit('SET_COUNT_DOWN_TIMES', times ?? 30);
    commit('SET_COUNT_DOWN_TIMER', setInterval(() => {
      commit('SET_COUNT_DOWN_TIMES', state.countDownTimes - 1);
      if (state.countDownTimes < 1) {
        clearInterval(state.countDownTimer);
        commit('SET_COUNT_DOWN_TIMER', null);
      }
    }, 1000));
  },
  // lucky draw
  actionGetLuckyDrawVisible({ commit }) {
    getLuckyDrawVisible()
      .then(({ data }) => {
        commit('SET_LUCKY_DRAW_PAGE_STATUS', data.visible);
      });
  },
  actionSetAvailableTags({ commit }) {
    commit('SET_AVAILABLE_TAGS');
  },
  clearPromotionList({ commit }) {
    commit('SET_PROMOTION_INFO_LIST', []);
  },
  actionGetLotteryTicket: ({ commit }) => getLotteryTicket()
    .then((res) => {
      commit('SET_LOTTERY_TICKET', res.data.remainingTicketAmount);
    }),
};

const getters = {
  getPromotionRecommend: (state) => state.promotionRecommend,
  getPromotionInfoIdxByCode: (state) => (tagCode) => {
    const promotionInfoIdx = state.tags.findIndex((tag) => tag.code === tagCode);
    return promotionInfoIdx;
  },
  // promotion tag
  getPromotionInfoTagName: (state) => (promotionInfoTagList) => {
    if (!Array.isArray(promotionInfoTagList)) return [];
    return promotionInfoTagList
      .reduce(
        (result, tag) => {
          const target = state.tags.find((i) => i.promotionInfoTagId === tag);
          if (target) result.push(target.name);
          return result;
        },
        [],
      )
      .join(', ');
  },
  getPromotionTagByCode: (state) => (tagCode) => state.tags.find((tag) => tag.code === tagCode),
  getLuckyDrawVisible: (state) => state.luckyDrawPageStatus,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
