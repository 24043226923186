import {
  getHistory,
  getHistoryDetail,
  getInProgressInfo,
  getRankingByUser,
  getTournamentVisible,
} from '~/api-tc/tournament';
import CONSTANT_MISSION_CENTER from '~/utils/constant/missionCenter';

const DEFAULT_DETAIL = () => ({
  rankingList: [],
  totalReward: 0,
  rewardType: CONSTANT_MISSION_CENTER.rewardType.AMOUNT,
  memberInfo: {
    rank: 0,
    betAmount: 0,
    nowReward: 0,
    totalReward: 0,
    rewardType: CONSTANT_MISSION_CENTER.rewardType.AMOUNT,
    lastUpdateTime: '',
    endTime: null,
  },
});

const state = () => ({
  visible: {
    currentVisible: false,
    lastVisible: false,
  },
  currentDetail: DEFAULT_DETAIL(),
  historyDetail: DEFAULT_DETAIL(),
});

const mutations = {
  SET_VISIBLE: (state, data) => {
    state.visible = data;
  },
  SET_INFO: (state, data) => {
    state.info = data;
  },
  SET_RANKING: (state, data) => {
    state.ranking = data;
  },
  SET_CURRENT_DETAIL: (state, data) => {
    state.currentDetail = data;
  },
  SET_HISTORY_DETAIL: (state, data) => {
    state.historyDetail = data;
  },
};

const actions = {
  actionGetVisible: async ({ commit }) => {
    const { data } = await getTournamentVisible();
    commit('SET_VISIBLE', data);
  },
  actionGetCurrentInfo: async ({ commit }) => {
    const { data } = await getInProgressInfo();
    commit('SET_INFO', data);
  },
  actionGetCurrentDetail: async ({ commit }) => {
    const { data } = await getRankingByUser();
    commit('SET_CURRENT_DETAIL', data);
  },
  actionGetHistory: async ({ commit }) => {
    // 取上一期錦標賽的 promotionId
    const { data: historyData } = await getHistory({
      sorts: [
        {
          columnName: 'addTime',
          orderType: 'asc',
        },
      ],
      limit: {
        startIndex: 0,
        pageSize: 1,
      },
    });
    if (!historyData.records.length) {
      commit('SET_HISTORY_DETAIL', DEFAULT_DETAIL());
      return;
    }

    const { promotionId } = historyData.records[0];
    // 取上一期錦標賽資訊+當前會員的排名資訊
    const { data } = await getHistoryDetail({
      promotionId,
    });
    data.memberInfo = {
      ...data.memberInfo,
      ...historyData.records[0],
      totalReward: data.totalReward,
    };
    commit('SET_HISTORY_DETAIL', data);
  },
};

const getters = {
  tournamentVisible: (state) =>
    state.visible.currentVisible || state.visible.lastVisible,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
